import React, {useEffect, useMemo, useState} from 'react';
import useConfig from "../../../hooks/useConfig";
import {useGetShipmentsQuery} from "../../Shipments/ShipmentApiSlice";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import AdminShipmentRow from "./AdminShipmentRow";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link, useNavigate} from "react-router-dom";
import {getShipmentsStats} from "../../../utils/getShipmentsStats";
import CustomSelect from "../../../components/Reusable/CustomSelect";
import AdminDispatchForm from "./AdminDispatchForm";
import {useCreateDispatchOrderMutation} from "../../DispatchOrders/dispatchOrderApiSice";

const AdminShipmentsList = () => {
    const [err, setErr] = useState(null)
    const {CONSTANTS: {SHIPMENT_STATUS_LIST, SHIPMENT_STATUS_TRANSLATION}} = useConfig()
    const navigate = useNavigate()

    const [state, setState] = useState({
        shipments: null,
        filter:
            {id: 2, name: "confirmed"},
        quantities: {
            created: null,
            confirmed: null
        },
        dispatchList: [],
        comment: "",
        showDispatchForm: false
    })
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetShipmentsQuery('shipmentsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [createDispatchOrder, {
        dispatchOrder,
        isLoading: isCreateDispatchOrderLoading,
        isSuccess: isCreateDispatchOrderSuccess,
        isError: isCreateDispatchOrderError,
        error: createDispatchOrderError
    }] = useCreateDispatchOrderMutation()

    useEffect(() => {
        if (isCreateDispatchOrderSuccess) navigate('/admin/dispatch_orders')
    }, [isCreateDispatchOrderSuccess]);

    useEffect(() => {
        if (isSuccess && data) setState(prev => ({
            ...prev,
            quantities: getShipmentsStats(data),
            shipments: data
        }))
    }, [data]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isCreateDispatchOrderError) setErr(getErrorMessage(createDispatchOrderError));
    }, [error, isError, createDispatchOrderError, isCreateDispatchOrderError]);

    const filteredShipments = useMemo(() => {
        if (!state.shipments) return {ids: [], entities: {}};
        return state.shipments.ids.reduce((acc, id) => {
            if (state.shipments.entities[id]?.status === state.filter.name) {
                acc.ids.push(id);
                acc.entities[id] = state.shipments.entities[id];
            }
            return acc;
        }, {ids: [], entities: {}});
    }, [state.shipments, state.filter.name]);

    const clearError = () => {
        setErr(null);
    };

    const changeFilter = (e) => {
        const name = e.target.dataset.id
        setState(prev => ({
            ...prev,
            filter: SHIPMENT_STATUS_LIST.find(el => el.name === name)
        }));
    }

    const onShowDispatchOrderFormClicked = () => {
        setState(prev => ({
            ...prev,
            showDispatchForm: !prev.showDispatchForm
        }))
    }

    const onCreateDispatchOrderClicked = async () => {
        try {
            await createDispatchOrder({
                data: state.dispatchList,
                comment: state.comment
            }).unwrap()

            setState(prev => ({
                ...prev,
                showDispatchForm: false,
                dispatchList: [],
                comment: ""
            }))

        } catch (err) {
            console.error(getErrorMessage(error))
        }
    }

    let result = null
    if (isLoading) return <h1>Loading...</h1>
    if (state.shipments) {
        const {ids} = filteredShipments
        const rows = ids.map((id, i) => {
            return <AdminShipmentRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
                setState={setState}
                dispatchList={state.dispatchList}
                i={i}
            />
        })

        result = (<section className="admin__panel__section admin__shipments col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1 className="admin__panel__section-title">PRZESYŁKI</h1>
            {state.showDispatchForm
                ? <AdminDispatchForm
                    setState={setState}
                    state={state}
                    create={onCreateDispatchOrderClicked}
                />
                : null}
            <div className="admin__panel__section__buttons admin__shipments__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                <div className="admin__panel__section__buttons-group admin__shipments__buttons__group">

                    {state.dispatchList.length
                        ? <button
                            className="blue-button created"
                            onClick={onShowDispatchOrderFormClicked}
                        >
                            <span className="notification">{state.dispatchList.length}</span>
                            Zlecenie odbioru
                        </button>
                        : null}
                    <button
                        className={state.filter.name === "created"
                            ? "blue-button created selected"
                            : "blue-button created"}
                        onClick={changeFilter}
                        data-id="created"
                    >
                        {state.quantities.created ?
                            <span className="notification">{state.quantities.created}</span> : null}
                        Utworzone
                    </button>

                    <button
                        className={state.filter.name === "confirmed"
                            ? "blue-button confirmed selected"
                            : "blue-button confirmed"} onClick={changeFilter}
                        data-id="confirmed"
                    >
                        Potwierdzone
                        {state.quantities.confirmed ?
                            <span className="notification">{state.quantities.confirmed}</span> : null}
                    </button>
                    <div className="select-container">
                        <CustomSelect
                            selected={state.filter}
                            list={SHIPMENT_STATUS_LIST}
                            attribute="filter"
                            attributeId="filter"
                            placeholder="Wybierz filtr"
                            className="filter"
                            setSelected={setState}
                            translation={SHIPMENT_STATUS_TRANSLATION}
                        />
                    </div>
                </div>
            </div>
            <div className="admin__panel__section__list admin__shipments__list ">
                <div className="admin__panel__section__list-item col-1 list-header"></div>
                <div className="admin__panel__section__list-item col-2 list-header">Nr zamówienia</div>
                <div className="admin__panel__section__list-item col-3 list-header">Nr przesyłki</div>
                <div className="admin__panel__section__list-item col-3 list-header">Data</div>
                <div className="admin__panel__section__list-item col-2 product__price list-header ">Typ</div>
                <div className="admin__panel__section__list-item col-2 product__kind list-header">Status</div>
                <div className="admin__panel__section__list-item col-3 product__kind list-header">Sposób nadania</div>
                <div className="admin__panel__section__list-item col-2 list-header">Edycja</div>
            </div>
            {rows}
        </section>)
        return result
    }
};


export default AdminShipmentsList;