import {ReactComponent as Ig} from "../../images/ig.svg";
import {ReactComponent as Fb} from "../../images/fb.svg";
import {ReactComponent as Mail} from "../../images/mail.svg";
import {Link} from "react-router-dom";
import useConfig from "../../hooks/useConfig";

const Footer = () => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const footer = (
        <section className='footer'>
            <div className='row'>
                <div className='col-12 col-md-5 footer__card'>
                    <h1 className='footer__card-title'>INFORMACJE:</h1>
                    <Link to="rules" className="footer__card-content">Regulamin</Link>
                    <Link to="consts_and_shipping" className="footer__card-content">Koszty i metody dostawy</Link>
                    <Link to="gift_card_rules" className="footer__card-content">Regulamin Karty
                        Prezentowej</Link>
                </div>
                <div className='col-12 col-md-5 footer__card'>
                    <h1 className='footer__card-title'>ZWROTY:</h1>
                    <Link to="returns" className="footer__card-content">Regulamin i formularze</Link>
                    <Link to="privacy_policy" className="footer__card-content">Polityka Prywatności</Link>
                </div>
                <div className='col-12 col-md-5 footer__card'>
                    <h1 className='footer__card-title'>KONTAKT:</h1>
                    <a
                        href="mailto:kontakt@bebrave.com.pl"
                        className="footer__card-content">
                        kontakt@bebrave.com.pl
                    </a>
                    {/*<p className="footer__card-content">tel. +48 510 669 711</p>*/}
                    <p className="footer__card-content">pon-pt 9:00-17:00</p>
                    <div className='socials'>
                        <Link
                            className='social'
                            to="https://www.facebook.com/bebreve.wear"
                            target="_blank"
                            rel="noopener noreferrer">
                            <Fb/>
                        </Link>
                        <Link
                            className='social'
                            to="https://www.instagram.com/bebrave.wear/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Ig/>
                        </Link>
                        <Link
                            className='social'
                            to="mailto:kontakt@beberave.com.pl">
                            <Mail/>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='info col-18 col-md-12'>
                <span className="copy"> &copy; BeBrave. All rights reserved.</span>
                <div className='payment__info'>
                    <span className="payment__info-text">Płatności obsługuje:</span>
                    <img
                        className='payment__info-banner'
                        src={`${SERVER_ADDRESS}/img/Przelewy24_logo.png`}
                    />
                </div>
            </div>
        </section>
    )
    return footer
};

export default Footer;