import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";


const dispatchOrdersAdapter = createEntityAdapter({});

const initialState = dispatchOrdersAdapter.getInitialState();

export const dispatchOrderApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDispatchOrders: builder.query({
            query: () => ({
                url: '/api/admin/shipments/dispatch_orders',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedDispatchOrders = responseData.map(dispatchOrder => {
                    dispatchOrder.dispatchOrderId = dispatchOrder.id;
                    dispatchOrder.id = dispatchOrder._id;
                    return dispatchOrder;
                }).sort((prev, curr) => new Date(curr.created_at) - new Date(prev.created_at)); // Compare as dates

                return dispatchOrdersAdapter.setAll(initialState, loadedDispatchOrders);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'DispatchOrder', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'DispatchOrder', id}))
                    ];
                } else return [{type: 'DispatchOrder', id: 'LIST'}];
            }
        }),

        fetchDispatchOrders: builder.mutation({
            query: () => ({
                url: '/api/admin/shipments/dispatch_orders/fetch',
                method: 'POST',
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            invalidatesTags: (result, error, arg) => [
                {type: 'DispatchOrder', id: 'LIST'},
            ],
        }),

        createDispatchOrder: builder.mutation({
            query: (initialDispatchOrderData) => ({
                url: '/api/admin/shipments/dispatch_orders',
                method: 'POST',
                body: {
                    ...initialDispatchOrderData
                },
                validateStatus: (response, result) => {
                    console.log(response.status);
                    return response.status === 200 || response.status === 201 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'DispatchOrder', id: 'LIST'},
                {type: 'Shipment', id: 'LIST'},
            ],
        }),

        updateDispatchOrder: builder.mutation({
            query: (initialDispatchOrderData) => ({
                url: '/api/admin/shipments/dispatch_orders',
                method: 'PATCH',
                body: {
                    ...initialDispatchOrderData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'DispatchOrder', id: arg.id},
            ],
        }),

        deleteDispatchOrder: builder.mutation({
            query: (deleteData) => ({
                url: '/api/admin/shipments/dispatch_orders',
                method: 'DELETE',
                body: {...deleteData}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'DispatchOrder', id: arg.id}
            ],
        })
    })
});

export const {
    useGetDispatchOrdersQuery,
    useFetchDispatchOrdersMutation,
    useCreateDispatchOrderMutation,
    useUpdateDispatchOrderMutation,
    useAddNewDispatchOrderMutation,
    useDeleteDispatchOrderMutation,
} = dispatchOrderApiSlice;

// returns the query object
export const selectDispatchOrdersResult = dispatchOrderApiSlice.endpoints.getDispatchOrders.select();
// returns the query object

// creates memoized selector
const selectDispatchOrdersData = createSelector(
    selectDispatchOrdersResult,
    dispatchOrdersResult => dispatchOrdersResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllDispatchOrders,
    selectById: selectDispatchOrderById,
    selectIds: selectDispatchOrderIds
} = dispatchOrdersAdapter.getSelectors(state => selectDispatchOrdersData(state) ?? initialState);
