import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import {useAdminUpdateClientMutation, useGetClientsQuery, useUpdateClientMutation} from "../../Clients/clientApiSlice";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import PanelSectionSelect from "../AdminPanelSection/PanelSectionSelect";
import PanelSectionCheckBox from "../AdminPanelSection/PanelSectionCheckBox";
import {adminValidateClient} from "../../../utils/adminValidateClient";
import useGetLocations from "../../../hooks/useGetLocations";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminEditClient = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const [client, setClient] = useState(null)
    const [check, setCheck] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const clientId = useQuery('id');
    const locations = useGetLocations()
    locations.pop()
    const CONFIG = useConfig()

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetClientsQuery("clientsList", {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[clientId],
            ...other
        })
    });

    const [updateClient, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useAdminUpdateClientMutation()

    useEffect(() => {
        if (isSuccess && data) {
            setIsValid(adminValidateClient(data, CONFIG));
            setClient(data)
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (client) {
            setFormError(null);
            setIsValid(adminValidateClient(client, CONFIG));
        }
    }, [client]);

    useEffect(() => {
        if (isUpdateSuccess) navigate("/admin/clients")
    }, [updateResult, isUpdateSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isUpdateError) setErr(getErrorMessage(updateError));
    }, [error, isError, updateError, isUpdateError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        const {value, dataset: {id}} = e.target;
        setClient(prev => ({...prev, [id]: value}))
    }

    const setAddress = (e) => {
        let {value, dataset: {id}} = e.target;
        setClient(prev => (
            {
                ...prev,
                addresses: {
                    ...prev.addresses,
                    [id]: value
                }
            }))
    }

    const setCheckBox = (e) => {
        const {checked, dataset: {id}} = e.target;

        if (id === "theSame") {
            setClient(prev => ({
                ...prev,
                addresses: {
                    ...prev.addresses,
                    billingAddress: {
                        ...prev.addresses.billingAddress,
                        theSame: !prev.addresses.billingAddress.theSame
                    }
                }
            }))
        } else {
            setClient(prev => ({...prev, [id]: checked}))
        }
    }

    const setBillingData = (e) => {
        const {value, dataset: {id}} = e.target;

        setClient(prev => ({
            ...prev,
            addresses: {
                ...prev.addresses,
                billingAddress: {
                    ...prev.addresses.billingAddress,
                    [id]: value
                }
            }
        }))
    }

    const onSaveClientClicked = async (e) => {
        e.preventDefault()
        // Check if form is valid
        setCheck(true)
        if (!isValid.all) {
            setFormError('Dane klienta  są niepoprawne lub niekompletne.');
            console.log('Order data incomplete');
            return
        }

        try {
            await updateClient(client).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista klientów"}
    </Link>)

    if (isLoading) return <h1>Loading...</h1>
    if (!client) return null;
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Klient: {client.email}</h1>
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <button
                    className=" admin__panel__section__buttons-button yellow-button"
                    onClick={onSaveClientClicked}
                >{isUpdateLoading ? "Loading..." : "Zapisz"}
                </button>
            </div>
            <form className="admin__panel__section__from edit__client">
                <PanelSectionCheckBox
                    checked={client.active}
                    title="KONTO"
                    name="active"
                    label="Konto aktywne"
                    setChecked={setCheckBox}
                />
                <PanelSectionCheckBox
                    checked={client.subscribe}
                    title="SUBSKRYBCJA"
                    name="subscribe"
                    label="Zapisany do newslettera."
                    setChecked={setCheckBox}
                />

                <PanelSectionInput
                    isValid={isValid.email}
                    check={check}
                    value={client.email}
                    type="email"
                    setInput={setInput}
                    title="E-MAIL"
                    name="email"
                    placeholder="e-mail"
                />
                <PanelSectionInput
                    isValid={isValid.name}
                    check={check}
                    value={client.name}
                    type="text"
                    setInput={setInput}
                    title="IMIĘ"
                    name="name"
                    placeholder="Imię"
                />
                <PanelSectionInput
                    isValid={isValid.surname}
                    check={check}
                    value={client.surname}
                    type="text"
                    setInput={setInput}
                    title="NAZWISKO"
                    name="surname"
                    placeholder="Nazwisko"
                />
                <PanelSectionSelect
                    isValid={isValid.country}
                    check={check}
                    title="KRAJ"
                    placeholder="Wybierz kraj"
                    attribute="country"
                    attributeId="shipmentCountry"
                    selected={client.addresses.country}
                    setState={setClient}
                    list={CONFIG.CONSTANTS.COUNTRIES}
                />
                <PanelSectionInput
                    isValid={isValid.street}
                    check={check}
                    value={client.addresses.street}
                    type="text"
                    setInput={setAddress}
                    title="ULICA"
                    name="street"
                    placeholder="Ulica"
                />
                <PanelSectionInput
                    isValid={isValid.buildingNumber}
                    check={check}
                    value={client.addresses.buildingNumber}
                    type="text"
                    setInput={setAddress}
                    title="NR DOMU"
                    name="buildingNumber"
                    placeholder="Nr domu"
                />
                <PanelSectionInput
                    isValid={isValid.postCode}
                    check={check}
                    value={client.addresses.postCode}
                    type="text"
                    setInput={setAddress}
                    title="KOD POCZTOWY"
                    name="postCode"
                    placeholder="Kod pocztowy"
                />
                <PanelSectionInput
                    isValid={isValid.city}
                    check={check}
                    value={client.addresses.city}
                    type="text"
                    setInput={setAddress}
                    title="MIASTO"
                    name="city"
                    placeholder="Miasto"
                />

                <PanelSectionCheckBox
                    checked={client.addresses.billingAddress.theSame}
                    title="DANE DO FAKTURY"
                    name="theSame"
                    label="Takie same jak dane dostawy"
                    setChecked={setCheckBox}
                />

                {!client.addresses.billingAddress.theSame && <>
                    <PanelSectionInput
                        isValid={isValid.billingAddress.name}
                        check={check}
                        value={client.addresses.billingAddress.name}
                        type="text"
                        setInput={setBillingData}
                        title="IMIĘ"
                        name="name"
                        placeholder="Imię"
                    />
                    <PanelSectionInput
                        isValid={isValid.billingAddress.surname}
                        check={check}
                        value={client.addresses.billingAddress.surname}
                        type="text"
                        setInput={setBillingData}
                        title="NAZWISKO"
                        name="surname"
                        placeholder="Nazwisko"
                    />
                    <PanelSectionSelect
                        isValid={isValid.billingAddress.country}
                        check={check}
                        title="KRAJ"
                        placeholder="Wybierz kraj"
                        attribute="country"
                        attributeId="billingCountry"
                        selected={client.addresses.billingAddress.country}
                        setState={setClient}
                        list={CONFIG.CONSTANTS.COUNTRIES}
                    />
                    <PanelSectionInput
                        isValid={isValid.billingAddress.street}
                        check={check}
                        value={client.addresses.billingAddress.street}
                        type="text"
                        setInput={setBillingData}
                        title="ULICA"
                        name="street"
                        placeholder="Ulica"
                    />
                    <PanelSectionInput
                        isValid={isValid.billingAddress.buildingNumber}
                        check={check}
                        value={client.addresses.billingAddress.buildingNumber}
                        type="text"
                        setInput={setBillingData}
                        title="NR DOMU"
                        name="buildingNumber"
                        placeholder="Nr domu"
                    />
                    <PanelSectionInput
                        isValid={isValid.billingAddress.postCode}
                        check={check}
                        value={client.addresses.billingAddress.postCode}
                        type="text"
                        setInput={setBillingData}
                        title="KOD POCZTOWY"
                        name="postCode"
                        placeholder="Kod pocztowy"
                    />
                    <PanelSectionInput
                        isValid={isValid.billingAddress.city}
                        check={check}
                        value={client.addresses.billingAddress.city}
                        type="text"
                        setInput={setBillingData}
                        title="MIASTO"
                        name="city"
                        placeholder="Miasto"
                    />
                </>}
            </form>
        </section>)
}


export default AdminEditClient;