import React, {useEffect, useState} from 'react';
import {ReactComponent as Add} from "../../../images/add.svg";
import {useGetProductsQuery} from "../producsApiSlice";
import {addItem, selectAll, updateCartAfterAction} from "../../Cart/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {useParams} from "react-router-dom";
import ProductViewAddedProduct from "./ProductViewAddedProduct";
import ProductPictures from "./ProductPictures";
import DOMPurify from "dompurify";
import {marked} from "marked";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import ProductPrice from "./ProductPrice";
import useConfig from "../../../hooks/useConfig";
import ProductDetail from "./ProductDetail";
import SubmitButton from "../../../components/Reusable/SubmitButton";

const ProductView = () => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const dispatch = useDispatch();
    const {productName} = useParams();
    const [err, setErr] = useState();
    const [addedProduct, setAddedProduct] = useState(null)
    const [state, setState] = useState({
        expandedSection: null,
        error: null,
        selectedSize: null,
        availableSizes: {},
        selectedColor: null,
        colorOptions: []
    });
    const {error: cartError, cart: {items}} = useSelector(selectAll)
    const {
        product,
        isError,
        error
    } = useGetProductsQuery("productList", {
        selectFromResult: ({data, ...other}) => ({
            product: data?.entities ? Object.values(data?.entities).find(el => el.name === productName) : undefined,
            ...other
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });
    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (cartError) setErr(getErrorMessage(cartError));
    }, [error, isError, cartError]);

    const clearError = () => {
        setErr(null);
    };

    // Effect to update local state when the product changes
    useEffect(() => {
        if (!product) return

        // const initialColorOptions = [];
        const initialColorOptions = product.quantities.list;
        const initialSizes = {};

        // Populate color options and initial size quantities
        product.quantities?.colors.forEach(el => {
            // if (!initialColorOptions.includes(el.color)) initialColorOptions.push(el.color);

            // Getting quantities of products already in the cart
            const {quantity: quantityInCart} = items.find(inCart => inCart.color === el.color && inCart.size === el.size) || {quantity: 0}

            // Setting quantities from product - quantities in te cart
            if (el.color === state.selectedColor) {
                initialSizes[el.size] = el.quantity - quantityInCart;
            }
        });

        //HERE EVERYTHING IS TRIGGERED
        setState(prevState => ({
            ...prevState,
            colorOptions: initialColorOptions,
            selectedColor: state.selectedColor ? state.selectedColor : initialColorOptions[0],
            availableSizes: initialSizes
        }));

    }, [product, items, state.selectedColor]);

    const handleSizeSelection = (e) => {
        const size = e.target.dataset.id;
        setState(prevState => ({
            ...prevState,
            selectedSize: size,
            error: prevState.error ? null : prevState.error // ????
        }));
    };

    const handleToggleDetail = (event) => {
        const element = state.expandedSection === event.target.id ? null : event.target.id;
        console.log(event.target.id);
        setState(prevState => ({
            ...prevState,
            expandedSection: element
        }));
    };

    const handleAddItem = () => {
        // Check if the product is available
        if (!Object.values(state.availableSizes).reduce((prev, curr) => prev + curr)) {
            setState(prevState => ({
                ...prevState,
                error: 'Produkt niedostępny' // Product not available
            }));
            return;
        }

        // Check if a size is selected
        if (!state.selectedSize) {
            setState(prevState => ({
                ...prevState,
                error: 'Wybierz rozmiar'
            }));
            return;
        }

        // Check if the selected size is available
        if (!state.availableSizes[state.selectedSize]) {
            setState(prevState => ({
                ...prevState,
                error: 'Produkt niedostępny' // Product not available
            }));
            return;
        }
        dispatch(updateCartAfterAction(addItem({
            id: product.id,
            name: product.name,
            price: product.price,
            isPromotion: product.promotion.isPromotion,
            promotionAmount: product.promotion.promotionAmount,
            basePrice: product.promotion.basePrice,
            color: state.selectedColor,
            size: state.selectedSize,
        })));

        setAddedProduct({
            type: "product",
            image: `${SERVER_ADDRESS}/img/${product.id}/${product.pictures[0]}`,
            name: product?.name,
            kind: product?.kind,
            color: state.selectedColor,
            size: state.selectedSize,
            amount: null
        })

        if (!cartError) setState(prevState => ({
            ...prevState,
            availableSizes: {
                ...prevState.availableSizes,
                [state.selectedSize]: prevState.availableSizes[state.selectedSize] - 1
            }
        }));
    };

    const changeColor = (e) => {
        const {color} = e.target.dataset
        setState(prev => ({
            ...prev,
            selectedColor: color
        }))
        window.scrollTo(0, 0);
    }

    if (!product) return null;

    const description = <div className="md-body product__description"
                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(product.description))}}/>
    const material = <div className="md-body product__description"
                          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(product.material))}}/>
    const sizeChart = <div className="md-body product__description"
                           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(product.sizeChartInfo))}}/>

    let colors = null
    if (state.colorOptions.length > 1) {
        colors = state.colorOptions.map(el => <button
            type="button"
            key={el}
            onClick={changeColor}
            data-color={el}
            className={el === state.selectedColor
                ? "link-button product__colors-color product__colors-selected"
                : "link-button product__colors-color"}
        >{el}</button>)
    }

    const sizeButtons = product.quantities.colors.map(el => {
        if (el.color === state.selectedColor) {
            return <button key={el._id}
                           data-id={el.size}
                           className={state.selectedSize === el.size ? "choose__size choose__size-selected" : "choose__size"}
                           onClick={handleSizeSelection}
                           disabled={state.availableSizes[el.size] <= 0}
            >{el.size}
            </button>
        }
    })
    return (
        <section className='product row'>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {addedProduct ? <ProductViewAddedProduct
                addedProduct={addedProduct}
                setAddedProduct={setAddedProduct}
            /> : null}

            <ProductPictures product={product} color={state.selectedColor}/>

            <div className="product__info col-18 col-md-9">
                <div className="product__info-content">
                    <p className='product__kind'>{product.kind}</p>
                    <h2 className='product__name'>{product.name}</h2>
                    <p className='product__price-text'>cena</p>
                    <ProductPrice product={product}/>
                    <div className="product__colors">
                        {colors}
                    </div>

                    <div className='product__sizes'>
                        <div className='product__sizes-buttons'>
                            {sizeButtons}
                        </div>
                    </div>
                    <SubmitButton
                        type="button"
                        errorMessage={state.error}
                        text="DODAJ DO KOSZYKA"
                        className="link-button"
                        onClick={handleAddItem}
                    />
                    <ProductDetail
                        name="description"
                        title="OPIS"
                        checked={state.expandedSection === "description"}
                        content={description}
                        handleToggleDetail={handleToggleDetail}
                    />
                    <ProductDetail
                        name="material"
                        title="SKŁAD I PIELĘGNACJA"
                        checked={state.expandedSection === "material"}
                        content={material}
                        handleToggleDetail={handleToggleDetail}
                    />
                    <ProductDetail
                        name="sizeChart"
                        title="TABELA ROZMIARÓW"
                        checked={state.expandedSection === "sizeChart"}
                        content={sizeChart}
                        handleToggleDetail={handleToggleDetail}
                    />
                </div>
            </div>
        </section>
    );
};

export default ProductView;
