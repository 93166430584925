import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {noBackgroundScroll} from "../../utils/noBackgroundScroll";

const RodoConsent = ({setShowConsent}) => {

    useEffect(() => {
        noBackgroundScroll("add")
        // Cleanup the effect when the component is unmounted
        return () => {
            noBackgroundScroll("remove");
        };
    }, []);

    const handleAccept = () => {
        localStorage.setItem("rodoAccepted", "true");
        setShowConsent(false); // Hide the widget after accepting
    };


    return (
        <div className="overlay">
            <div className="rodo col-18 col-md-12">
                <h2 className="rodo-title">Informacja o przetwarzaniu danych</h2>
                <p className="rodo-text">
                    Na naszej stronie używamy plików cookie wyłącznie w celu
                    uwierzytelniania użytkowników oraz przetwarzamy dane osobowe w celu
                    realizacji zamówień. Szczegóły znajdziesz w naszej{" "}
                </p>
                <Link
                    to="/privacy_policy"
                    className="rodo-link"
                >Polityce Prywatności</Link>
                <button
                    className="link-button"
                    onClick={handleAccept}
                >Akceptuję
                </button>
            </div>
        </div>
    );
};

export default RodoConsent;
