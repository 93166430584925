export const validateAddressData = (data, CONFIG) => {
    const {name, surname, email, phone, postCode, street, buildingNumber, city, country} = data
    const validateOption = (option, list) => list.some(el => el.name === option);
    let {REG_EX, CONSTANTS: {COUNTRIES}} = CONFIG
    const COUNTRY = country.name.replace(" ", "_").toUpperCase()

    const isValid = {
        name: REG_EX.NAME.test(name),
        surname: REG_EX.NAME.test(surname),
        email: REG_EX.EMAIL.test(email),
        phone: REG_EX[COUNTRY].PHONE_NUMBER.test(phone),
        postCode: REG_EX[COUNTRY].POST_CODE.test(postCode),
        street: REG_EX.STREET_NAME.test(street),
        buildingNumber: REG_EX.STREET_NAME.test(buildingNumber),
        city: REG_EX.EU_CITY_NAME.test(city),
        country: validateOption(country.name, COUNTRIES),
    }
    const all = Object.values(isValid).every(el => el === true)

    return {...isValid, all}
}

