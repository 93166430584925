import React, {useEffect, useMemo, useState} from 'react';
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link} from "react-router-dom";
import CustomSelect from "../../../components/Reusable/CustomSelect";
import {useFetchDispatchOrdersMutation, useGetDispatchOrdersQuery} from "../../DispatchOrders/dispatchOrderApiSice";
import {getDispatchOrdersStats} from "../../../utils/getDisaptchOrdersStats";
import AdminDispatchOrderRow from "./AdminDispatchOrderRow";

const AdminDispatchOrdersList = () => {
    const [err, setErr] = useState(null)
    const {CONSTANTS: {DISPATCH_ORDER_STATUS_LIST, DISPATCH_ORDER_STATUS_TRANSLATION}} = useConfig()
    const [state, setState] = useState({
        dispatchOrders: null,
        filter:
            {id: 2, name: "sent"},
        quantities: {
            newDispatchOrder: null,
            sent: null
        }
    })
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDispatchOrdersQuery('dispatchOrdersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })
    const [fetchDispatchOrders,
        {
            data: fetchData,
            isLoading: isFetchLoading,
            isSuccess: isFetchSuccess,
            isError: isFetchError,
            error: fetchError
        }] = useFetchDispatchOrdersMutation()

    useEffect(() => {
        if (isSuccess && data) setState(prev => ({
            ...prev,
            quantities: getDispatchOrdersStats(data),
            dispatchOrders: data
        }))
    }, [data]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isFetchError) setErr(getErrorMessage(fetchError));
    }, [error, isError, fetchError, isFetchError]);

    const filteredDispatchOrders = useMemo(() => {
        if (!state.dispatchOrders) return {ids: [], entities: []}
        return state.dispatchOrders.ids.reduce((acc, id) => {
            const filter = state.filter.name === "newDispatchOrder" ? "new" : state.filter.name
            if (state.dispatchOrders.entities[id]?.status === filter) {
                acc.ids.push(id);
                acc.entities[id] = state.dispatchOrders.entities[id];
            }
            return acc;
        }, {ids: [], entities: {}});
    }, [state.dispatchOrders, state.filter])

    const clearError = () => {
        setErr(null);
    };

    const changeFilter = (e) => {
        const name = e.target.dataset.id
        setState(prev => ({
            ...prev,
            filter: DISPATCH_ORDER_STATUS_LIST.find(el => el.name === name)
        }));
    }

    const onFetchClicked = async () => {
        try {
            await fetchDispatchOrders().unwrap()
        } catch (err) {
            console.error(getErrorMessage(err))
        }
    }

    let result = null
    if (state.dispatchOrders) {
        const {ids} = filteredDispatchOrders
        const rows = ids.map((id, i) => {
            return <AdminDispatchOrderRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
            />
        })

        result = (<section className="admin__panel__section admin__dispatchOrders col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1 className="admin__panel__section-title">ZLECENIA ODBIORU</h1>
            <div className="admin__panel__section__buttons admin__dispatchOrders__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                <div className="admin__panel__section__buttons-group admin__dispatchOrders__buttons__group">
                    <button
                        type="button"
                        className="blue-button"
                        onClick={onFetchClicked}
                    >
                        Pobierz z InPost
                    </button>
                    <button
                        className={state.filter.name === "newDispatchOrder"
                            ? "blue-button newDispatchOrder selected"
                            : "blue-button newDispatchOrder"}
                        onClick={changeFilter}
                        data-id="newDispatchOrder"
                    >
                        {state.quantities.newDispatchOrder ?
                            <span className="notification">{state.quantities.newDispatchOrder}</span> : null}
                        Nowe
                    </button>

                    <button
                        className={state.filter.name === "sent"
                            ? "blue-button sent selected"
                            : "blue-button sent"} onClick={changeFilter}
                        data-id="sent"
                    >
                        Wysłane
                        {state.quantities.sent ?
                            <span className="notification">{state.quantities.sent}</span> : null}
                    </button>
                    <div className="select-container">
                        <CustomSelect
                            selected={state.filter}
                            list={DISPATCH_ORDER_STATUS_LIST}
                            attribute="filter"
                            attributeId="filter"
                            placeholder="Wybierz filtr"
                            className="filter"
                            setSelected={setState}
                            translation={DISPATCH_ORDER_STATUS_TRANSLATION}
                        />
                    </div>
                </div>
            </div>
            <div className="admin__panel__section__list admin__dispatchOrders__list ">
                <div className="admin__panel__section__list-item col-2 list-header">Nr zamówienia</div>
                <div className="admin__panel__section__list-item col-2 list-header">Nr zlecenia</div>
                <div className="admin__panel__section__list-item col-3 list-header">Data utworzenia</div>
                <div className="admin__panel__section__list-item col-2 product__price list-header ">Punkt odbioru</div>
                <div className="admin__panel__section__list-item col-2 product__price list-header ">Status</div>
                <div className="admin__panel__section__list-item col-2 product__kind list-header">Data odbioru</div>
                <div className="admin__panel__section__list-item col-2 product__kind list-header">Godzina odbioru</div>
                <div className="admin__panel__section__list-item col-3 list-header">Edycja</div>
            </div>
            {rows}
        </section>)
        return result
    }
}

export default AdminDispatchOrdersList;