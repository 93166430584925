import React, {useEffect} from 'react';
import {noBackgroundScroll} from "../../../utils/noBackgroundScroll";

const AdminDispatchForm = ({state, setState, create}) => {

    useEffect(() => {
        noBackgroundScroll("add")
        // Cleanup the effect when the component is unmounted
        return () => {
            noBackgroundScroll("remove");
        };
    }, []);

    const onCommentChange = (e) => {
        setState(prev => ({
            ...prev,
            comment: e.target.value
        }))
    }
    const onCancelClicked = () => {
        setState(prev => ({
            ...prev,
            showDispatchForm: !prev.showDispatchForm,
            dispatchList: []
        }))
    }
    const shipments = state.dispatchList.map(el => <p
        key={el.id}
        className="shipment"
    >Zamówienie nr: {el.orderNumber}</p>)
    return (
        <div className='overlay'>
            <div className="dispatch col-18 col-md-12">
                <h1>Stwórz zlecenie odbioru</h1>
                <form className='dispatch__form'>
                    <div className="dispatch__form__shipments">
                        {shipments}
                    </div>
                    <label htmlFor="comment">Komentarz</label>
                    <textarea
                        className="comment"
                        name="comment"
                        id="comment"
                        value={state.comment}
                        onChange={onCommentChange}
                    >

                    </textarea>

                </form>
                <div className='dispatch__form__buttons'>
                    <button
                        className='yellow-button'
                        type="button"
                        onClick={onCancelClicked}
                    >Anuluj
                    </button>
                    <button
                        className='blue-button'
                        type="button"
                        onClick={() => create()}
                        disabled={!state.dispatchList.length || !state.comment}
                    >Stwórz
                    </button>
                </div>
            </div>

        </div>
    );
};

export default AdminDispatchForm;