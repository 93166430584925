import React, {useEffect, useState} from 'react';
import AdminNewsletterHistoryRow from "./AdminNewsletterHistoryRow";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link} from "react-router-dom";
import {useGetNewsletterHistoryQuery} from "../../Newsletter/newsletterApiSlice";

const AdminNewsletterHistory = () => {
    const [err, setErr] = useState()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNewsletterHistoryQuery('newslettersList')

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    let result
    if (isLoading) {
        result = <h1>Wczytuję...</h1>
    }
    if (isSuccess) {
        const {ids} = data
        const rows = ids.map((id, i) => {
            return <AdminNewsletterHistoryRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
            />
        })
        if (isLoading) result = <h1>Wczytuję</h1>
        if (isSuccess) result = (
            <section className="admin__panel__section admin__newsletter col-18 col-lg-16">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <h1 className="admin__panel__section-title">NEWSLETTER - HISTORIA</h1>
                <div className="admin__panel__section__buttons">
                    <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                </div>
                <div className="admin__panel__section__list admin__newsletter__list">
                    <div className="admin__panel__section__list-item col-2 list-header">Nr</div>
                    <div className="admin__panel__section__list-item col-4 list-header">Tytuł</div>
                    <div className="admin__panel__section__list-item col-4 product__kind list-header">Data</div>
                    <div className="admin__panel__section__list-item col-3 list-header">Edycja</div>
                </div>
                {rows}
            </section>
        );
        return result
    }
};

export default AdminNewsletterHistory;