import React from 'react';
import useConfig from "../../../hooks/useConfig";

const AdminSendNewsLetterForm = ({state, setState, setFormError}) => {
    const {CONSTANTS: {NODE_ENV}} = useConfig()

    const setTitle = (e) => {
        setFormError("")
        setState((prev) => ({...prev, title: e.target.value}));
    };

    const handleFileChange = async (e) => {
        setFormError("")
        const file = e.target.files[0]; // Correctly access the first selected file
        if (file) {
            let newPreviewURL
            const url = URL.createObjectURL(file)

            NODE_ENV === "development"
                ? newPreviewURL = url
                : newPreviewURL = url.replace("http:", "https:");

            setState(prev => ({
                ...prev,
                selectedFile: file,
                previewURL: newPreviewURL
            }));
        } else {
            setState(prev => ({
                ...prev,
                previewURL: null
            }));
        }
    };
    return (
        <form className="edit__newsletter__form row">
            <div className="input-group title__input">
                <input
                    className="title__input-input"
                    type="text"
                    name="title"
                    data-id="title"
                    placeholder="Wpisz tytuł"
                    value={state.title}
                    onChange={setTitle}
                />
            </div>
            <div className="input-group file__input">
                <input
                    className="file__input-input"
                    type="file"
                    name="content"
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg, image/webp"
                />
            </div>
        </form>
    );
};

export default AdminSendNewsLetterForm;