import {useEffect, useMemo, useState} from 'react';
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link} from "react-router-dom";
import CustomSelect from "../../../components/Reusable/CustomSelect";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import useConfig from "../../../hooks/useConfig";
import {useGetPaymentsQuery, useTestAccessQuery} from "../../Payments/paymentApiSlice";
import {getPaymentStats} from "../../../utils/getPaymentSats";
import AdminPaymentRow from "./AdminPaymentRow";

const AdminPaymentsList = () => {
    const [err, setErr] = useState(null)
    const {CONSTANTS: {PAYMENT_STATUS_LIST, PAYMENT_STATUS_TRANSLATION}} = useConfig()
    const [test, setTest] = useState({
        test: false,
        result: null
    })
    const [state, setState] = useState({
        payments: null,
        filter:
            {id: 3, name: "confirmed"},
        quantities: {
            pending: null,
            processed: null,
            confirmed: null
        }
    })

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPaymentsQuery('paymentList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const {
        data: testData,
        isLoading: isTestLoading,
        isSuccess: isTestSuccess,
        isError: isTestError,
        error: testError
    } = useTestAccessQuery('accessTest', {
        skip: !test.test
    })

    useEffect(() => {
        if (!testData) return
        setTest(prev => ({
            ...test,
            result: testData
        }))

    }, [testData]);

    useEffect(() => {
        if (isSuccess && data) setState(prev => ({
            ...prev,
            quantities: getPaymentStats(data),
            payments: data
        }))
    }, [data]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isTestError) setErr(getErrorMessage(testError));
    }, [error, isError, testError, isTestError]);

    const filteredPayments = useMemo(() => {
        if (!state.payments) return {ids: [], entities: []}
        return state.payments.ids.reduce((acc, id) => {
            if (state.payments.entities[id]?.status === state.filter.name) {
                acc.ids.push(id);
                acc.entities[id] = state.payments.entities[id];
            }
            return acc;
        }, {ids: [], entities: {}});
    }, [state.payments, state.filter])

    const clearError = () => {
        setErr(null);
    };

    const changeFilter = (e) => {
        const name = e.target.dataset.id
        setState(prev => ({
            ...prev,
            filter: PAYMENT_STATUS_LIST.find(el => el.name === name)
        }));
    }
    let result = null
    if (state.payments) {
        const {ids} = filteredPayments
        const rows = ids.map((id, i) => {
            return <AdminPaymentRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
            />
        })

        result = (<section className="admin__panel__section admin__payments col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1 className="admin__panel__section-title">PŁATNOŚCI</h1>
            <div className="admin__panel__section__buttons admin__payments__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                <div className="admin__panel__section__buttons-group admin__payments__buttons__group">
                    <button
                        className="yellow-button"
                        onClick={() => setTest(prev => ({...test, test: true}))}
                        data-id="pending"
                    >
                        {test.result ? "Sukces!" : "Sprawdź połęczenie"}
                    </button>
                    <button
                        className={state.filter.name === "pending"
                            ? "blue-button pending selected"
                            : "blue-button pending"}
                        onClick={changeFilter}
                        data-id="pending"
                    >
                        {state.quantities.pending ?
                            <span className="notification">{state.quantities.pending}</span> : null}
                        Oczekujące
                    </button>

                    <button
                        className={state.filter.name === "processed"
                            ? "blue-button processed selected"
                            : "blue-button processed"}
                        onClick={changeFilter}
                        data-id="processed"
                    >
                        {state.quantities.processed ?
                            <span className="notification">{state.quantities.processed}</span> : null}
                        W realizacji
                    </button>

                    <button
                        className={state.filter.name === "confirmed"
                            ? "blue-button confirmed selected"
                            : "blue-button confirmed"} onClick={changeFilter}
                        data-id="confirmed"
                    >
                        Potwierdzone
                        {state.quantities.confirmed ?
                            <span className="notification">{state.quantities.confirmed}</span> : null}
                    </button>
                    <div className="select-container">
                        <CustomSelect
                            selected={state.filter}
                            list={PAYMENT_STATUS_LIST}
                            attribute="filter"
                            attributeId="filter"
                            placeholder="Wybierz filtr"
                            className="filter"
                            setSelected={setState}
                            translation={PAYMENT_STATUS_TRANSLATION}
                        />
                    </div>
                </div>
            </div>
            <div className="admin__panel__section__list admin__payments__list ">
                <div className="admin__panel__section__list-item col-1 list-header">Nr</div>
                <div className="admin__panel__section__list-item col-2 list-header">Kwota</div>
                <div className="admin__panel__section__list-item col-4 list-header">Data</div>
                <div className="admin__panel__section__list-item col-5 product__price list-header ">Produkty</div>
                <div className="admin__panel__section__list-item col-3 product__kind list-header">Status</div>
                <div className="admin__panel__section__list-item col-3 list-header">Edycja</div>
            </div>
            {rows}
        </section>)
        return result
    }
};

export default AdminPaymentsList;