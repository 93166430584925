import React, {useEffect, useState} from 'react';
import {useGetAllProductsQuery} from "../../Products/producsApiSlice";
import AdminProductRow from "./AdminProductRow";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link} from "react-router-dom";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminProductsList = () => {
    const [err, setErr] = useState();

    const {
        data: products,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllProductsQuery('adminProductsList',
        {
            pollingInterval: 60000,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true
        })

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    let result
    if (isLoading) result = <h1>Loading</h1>
    if (isSuccess) {
        const filteredData = products.ids.reduce((acc, id) => {
            if (products.entities[id]?.kind !== "Karta podarunkowa") {
                acc.ids.push(id);
                acc.entities[id] = products.entities[id];
            }
            return acc;
        }, {ids: [], entities: {}});

        const rows = filteredData.ids.map((id, i) => {
            return <AdminProductRow
                key={id}
                id={id}
                number={i + 1}
                setError={setErr}
            />
        })

        if (isSuccess) result = (
            <section className="admin__panel__section admin__products col-18 col-lg-16">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <h1 className="admin__panel__section-title">PRODUKTY</h1>
                <div className="admin__panel__section__buttons">
                    <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                    <Link className="admin__panel__section__buttons-button  yellow-button" to="add_product">Dodaj</Link>
                </div>
                <div className="admin__panel__section__list admin__products__list ">
                    <div className="admin__panel__section__list-item col-2 list-header">Wyświetl</div>
                    <div className="admin__panel__section__list-item col-5 list-header">Nazwa</div>
                    <div className="admin__panel__section__list-item col-4 product__kind list-header">Kategoria</div>
                    <div className="admin__panel__section__list-item col-2 product__price list-header ">Cena</div>
                    <div className="admin__panel__section__list-item list-header col-3">Ilość</div>
                    <div className="admin__panel__section__list-item col-2 list-header">Edycja</div>
                </div>
                {rows}
            </section>
        );
        return result
    }
}

export default AdminProductsList;