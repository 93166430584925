import React, {useEffect, useState} from 'react';
import useGetLocations from "../../../hooks/useGetLocations";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {useGetOrdersQuery} from "../../Orders/orderApiSlice";
import useQuery from "../../../hooks/useQuery";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {Link} from "react-router-dom";
import JsonViewer from "../../../components/Utils/JsonViewer";
import {useGetAllProductsQuery} from "../../Products/producsApiSlice";
import {useGetClientsQuery} from "../../Clients/clientApiSlice";


const AdminOrderJson = () => {
    const clientId = useQuery('id');
    const [err, setErr] = useState(null)
    const locations = useGetLocations()
    locations.pop()


    const {
        data,
        isError,
        error
    } = useGetClientsQuery("clientsList", {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[clientId],
            ...other
        })
    });

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista klientów"}
    </Link>)

    if (data) {
        return (
            <section className=" admin__panel__section  col-18 col-lg-16">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <h1>Klient: {data.email}</h1>
                <div className="admin__panel__section__buttons">
                    <div className="admin__panel__section__buttons-group">
                        {links}
                    </div>
                </div>
                <JsonViewer data={data}/>
            </section>
        )
    }
};

export default AdminOrderJson;