import {Link} from "react-router-dom";
import useConfig from "../../../hooks/useConfig";
import JsonViewer from "../../../components/Utils/JsonViewer";

const AdminConfig = () => {
    const CONFIG = useConfig()
    return (
        <section className="admin__panel__section admin__config col-18 col-lg-16">
            <h1 className="admin__panel__section-title">KONFIGURACJA</h1>
            <div className="admin__panel__section__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
            </div>
            <JsonViewer data={CONFIG}/>
        </section>

    );
};

export default AdminConfig;