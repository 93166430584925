import React from 'react';
import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditGiftCardRules = () => {
    return <AdminEditMarkdown
        fileName="privacy_policy.md"
        navigateAfter="/privacy_policy"
        title="Edycja Polityki Prywatności"
        className="privacy__policy__content-body"
    />
};

export default AdminEditGiftCardRules;