import React, {useEffect, useState} from "react";
import {getErrorMessage} from "../../utils/getErrorMessage";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import {useAddNewSubscriptionMutation} from "../Admin/Subscriptions/subscriptionsApiSlice";

const NewsLetter = () => {
    const [message, setFormMessage] = useState("");
    const [err, setErr] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [check, setCheck] = useState(false);
    const [email, setEmail] = useState("")
    const {id: clientId} = useAuth()
    const {REG_EX} = useConfig()

    const [subscribe, {
        isSuccess,
        isLoading,
        isError, error
    }] = useAddNewSubscriptionMutation();

    useEffect(() => {
        if (isError) {
            const message = getErrorMessage(error)
            console.error(message);
            setErr(message)
        }

        if (isSuccess) {
            setFormMessage(`Dziękujemy!`)
            setEmail("")
        }
    }, [error, isError, isSuccess]);

    const clearError = () => {
        setErr(null);
    };

    const onEmailChange = (e) => {
        const email = e.target.value
        setCheck(false)
        setFormMessage(null)
        setIsValid(REG_EX.EMAIL.test(email))
        setEmail(email)
    }

    const onSubscribeClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!email) {
            setFormMessage("Podaj email")
            return
        }
        if (!isValid) {
            setFormMessage("Niepoprawny adres!")
            return
        }

        const subscriberObject = clientId
            ? {email, client: {clientId, status: "Client"}}
            : {email, client: {status: "unregistered"}}

        try {
            await subscribe({...subscriberObject}).unwrap()
        } catch (err) {
            setEmail("")
            console.error(getErrorMessage(err))
        }
    }

    const result = (
        <div className="newsletter__container">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <div className="row newsletter__container__row">
                <div className='newsletter__container__row-title col-18 col-md-8'>
                    <span>NEWSLETTER</span>
                </div>
                <form
                    className='newsletter__container__row__subscribe col-18 col-md-10'
                    onSubmit={onSubscribeClicked}
                >
                    <div className='newsletter__container__row__subscribe__inputs'>
                        <div className="inputs__group">
                            <div className="inputs__group-input col-12 col-md-8"
                            >
                                <span className='error-message'>{message}</span>
                                <input
                                    className={check && !isValid ? "not-valid" : ""}
                                    name="e-mail"
                                    type="email"
                                    placeholder="Twój adres e-mail"
                                    value={email}
                                    onChange={onEmailChange}
                                />
                            </div>
                            <button
                                type="submit"
                                className='inputs__group-button col-12 col-md-6 col-lg-4'
                                disabled={isLoading}
                            >{isLoading ? "ZAPISUJĘ" : "ZAPISZ SIĘ"}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
    return result;
};

export default NewsLetter;