import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import LandingPage from "./features/LandingPage/LandingPage";
import Login from "./features/Auth/Login";
import Cart from "./features/Cart/Cart";
import Contact from "./features/Contact/Contact";
import About from "./features/About/About";
import Products from "./features/Products/ChooseProduct/Products";
import ProductView from "./features/Products/ProductView/ProductView";
import Rules from "./features/Footer/Rules";
import PaymentInfo from "./features/Footer/PaymentInfo";
import ShipmentAndCostsInfo from "./features/Footer/ShipmentAndCostsInfo";
import GiftCardRules from "./features/Footer/GiftCardRules";
import Returns from "./features/Footer/Returns";
import CreateAccount from "./features/Clients/ClientAccount/CreateAccount";
import Order from "./features/Orders/Order";
import Prefetch from "./app/Prefetch/Prefetch";
import OrderSummary from "./features/Orders/OrderSummary/OrderSummary";
import ClientPanel from "./features/Clients/ClientPanel/ClientPanel";
import PersistLogin from "./features/Auth/PersistLogin";
import RequireAuth from "./features/Auth/RequireAuth";
import NotFound from "./features/NotFound/NotFound";
import ConfirmAccount from "./features/Clients/ClientAccount/ConfirmAccount";
import ClientEdit from "./features/Clients/ClientPanel/ClientEdit/ClientEdit";
import ClientPanelOrderView from "./features/Clients/ClientPanel/ClientPanelOrderView";
import ShippingData from "./features/Clients/ClientPanel/ClientEdit/ShippingData";
import ClientPanelLayout from "./features/Clients/ClientPanel/ClientPanelLayout";
import BillingData from "./features/Clients/ClientPanel/ClientEdit/BillingData";
import PasswordChange from "./features/Clients/ClientPanel/ClientEdit/PasswordChange";
import RemoveAccount from "./features/Clients/ClientPanel/ClientEdit/RemoveAccount";
import ResetPassword from "./features/Clients/ClientPassword/ResetPassword";
import ConfirmResetPassword from "./features/Clients/ClientPassword/ConfirmResetPassword";
import AdminPanel from "./features/Admin/AdminPanel";
import VerifyAdmin from "./features/Auth/VerifyAdmin";
import Layout2 from "./features/Admin/AdminLayout";
import AdminProductsList from "./features/Admin/Products/AdminProductsList";
import AdminOrdersList from "./features/Admin/Orders/AdminOrdersList";
import AdminClientsList from "./features/Admin/Clients/AdminClientsList";
import AdminEditProduct from "./features/Admin/Products/AdminEditProduct";
import AdminAddProduct from "./features/Admin/Products/AdminAddProduct";
import AdminEditOrder from "./features/Admin/Orders/AdminEditOrder";
import AdminEditClient from "./features/Admin/Clients/AdminEditClient";
import AdminEditOrderProducts from "./features/Admin/Orders/AdminEditOrderProducts";
import AdminEditShopRules from "./features/Admin/Rules/AdminEditShopRules";
import AdminEditShipmentAndCosts from "./features/Admin/ShipmentAndCosts/AdminEditShipmentAndCosts";
import AdminEditPaymentInfo from "./features/Admin/ShipmentAndCosts/AdminEditPaymentInfo";
import AdminEditGiftCardRules from "./features/Admin/Rules/AdminEditGiftCardRules";
import AdminEditReturns from "./features/Admin/Rules/AdminEditReturns";
import AdminDiscountsList from "./features/Admin/Discounts/AdminDiscountsList";
import AdminEditDiscount from "./features/Admin/Discounts/AdminEditDiscount";
import AdminAddDiscount from "./features/Admin/Discounts/AdminAddDiscount";
import GiftCartView from "./features/Products/GiftCardView/GiftCartView";
import AdminAddGiftCard from "./features/Admin/GiftCard/AdminAddGiftCard";
import AdminEditGiftCard from "./features/Admin/GiftCard/AdminEditGiftCard";
import PrefetchConfig from "./app/Prefetch/PrefetchConfig";
import AdminConfig from "./features/Admin/Config/AdminConfig";
import AdminPaymentsList from "./features/Admin/Payments/AdminPaymentsList";
import AdminOrderJson from "./features/Admin/Orders/AdminOrderJson";
import AdminProductJson from "./features/Admin/Products/AdminProductJson";
import AdminClientJson from "./features/Admin/Clients/AdminClientJson";
import AdminPaymentsJson from "./features/Admin/Payments/AdminPaymentJson";
import AdminShipmentsList from "./features/Admin/Shipments/AdminShipmentsList";
import AdminShipmentsJson from "./features/Admin/Shipments/AdminShipmentJson";
import AdminDispatchOrdersList from "./features/Admin/DispatchOrders/AdminDispatchOrdersList";
import AdminDispatchOrdersJson from "./features/Admin/DispatchOrders/AdminDispatchOrderJson";
import PrivacyPolicy from "./features/Footer/PrivacyPolicy";
import AdminEditPrivacyPolicy from "./features/Admin/Rules/AdminEditPrivacyPolicy";
import Loading2 from "./features/LoadingPage/Loading2";
import Unsubscribe from "./features/Newsletter/Unsubscribe";
import AdminSubscriptionsList from "./features/Admin/Subscriptions/AdminSubscriptionsList";
import AdminSubscriptionJson from "./features/Admin/Subscriptions/AdminSubscriptionJson";
import AdminEditSubscription from "./features/Admin/Subscriptions/AdminEditSubscription";
import AdminSendNewsletter from "./features/Admin/Newsletter/AdminSendNewsletter";
import AdminNewsletterHistory from "./features/Admin/Newsletter/AdminNewsletterHistory";
import AdminNewsletterJson from "./features/Admin/Newsletter/AdminNewsletterJson";
import AdminEditAbout from "./features/Admin/About/AdminEditAbout";

function App() {
    return (
        <Routes>
            <Route element={<PersistLogin/>}>
                <Route element={<PrefetchConfig/>}>
                    <Route element={<Prefetch/>}>
                        <Route element={<Loading2/>}>
                            <Route path="/" element={<Layout/>}>
                                <Route index element={<LandingPage/>}/>
                                <Route path="contact" element={<Contact/>}/>
                                <Route path="about" element={<About/>}/>
                                <Route path="shop" element={<Products/>}/>
                                <Route path="shop/:productName" element={<ProductView/>}/>
                                <Route path="shop/gift_card" element={<GiftCartView/>}/>
                                <Route path="rules" element={<Rules/>}/>
                                <Route path="consts_and_shipping" element={<ShipmentAndCostsInfo/>}/>
                                <Route path="payment_methods" element={<PaymentInfo/>}/>
                                <Route path="gift_card_rules" element={<GiftCardRules/>}/>
                                <Route path="returns" element={<Returns/>}/>
                                <Route path="privacy_policy" element={<PrivacyPolicy/>}/>
                                <Route path="login" element={<Login/>}/>
                                <Route path="create_account" element={<CreateAccount/>}/>
                                <Route path="create_account/confirm" element={<ConfirmAccount/>}/>
                                <Route path="reset_password" element={<ResetPassword/>}/>
                                <Route path="reset_password/confirm" element={<ConfirmResetPassword/>}/>
                                <Route path="unsubscribe/:token" element={<Unsubscribe/>}/>
                                <Route path="cart" element={<Cart/>}/>
                                <Route path="order" element={<Order/>}/>
                                <Route path="order/:id" element={<OrderSummary/>}/>

                                {/* PROTECTED CLIENT ROUTES */}
                                <Route element={<RequireAuth/>}>
                                    <Route path="/" element={<ClientPanelLayout/>}>
                                        <Route path="account" element={<ClientPanel/>}/>
                                        <Route path='account/order_view/:id' element={<ClientPanelOrderView/>}/>
                                        <Route path='account/edit/' element={<ClientEdit/>}/>
                                        <Route path='account/edit/shipping' element={<ShippingData/>}/>
                                        <Route path='account/edit/billing' element={<BillingData/>}/>
                                        <Route path='account/edit/change_password' element={<PasswordChange/>}/>
                                        <Route path='account/edit/remove_account' element={<RemoveAccount/>}/>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    {/* PROTECTED ADMIN ROUTES */}
                    <Route element={<VerifyAdmin/>}>
                        <Route element={<Layout2/>}>
                            <Route index path="/admin" element={<AdminPanel/>}/>
                            <Route path="/admin/orders" element={<AdminOrdersList/>}/>
                            <Route path="/admin/payments" element={<AdminPaymentsList/>}/>
                            <Route path="/admin/orders/:id" element={<AdminEditOrder/>}/>
                            <Route path="/admin/orders/order/:id" element={<AdminEditOrderProducts/>}/>
                            <Route path="/admin/orders/json" element={<AdminOrderJson/>}/>
                            <Route path="/admin/orders" element={<AdminOrdersList/>}/>
                            <Route path="/admin/products" element={<AdminProductsList/>}/>
                            <Route path="/admin/products/:id" element={<AdminEditProduct/>}/>
                            <Route path="/admin/products/json" element={<AdminProductJson/>}/>
                            <Route path="/admin/products/add_product" element={<AdminAddProduct/>}/>
                            <Route path="/admin/clients" element={<AdminClientsList/>}/>
                            <Route path="/admin/clients/:id" element={<AdminEditClient/>}/>
                            <Route path="/admin/clients/json" element={<AdminClientJson/>}/>
                            <Route path="/admin/payments/json" element={<AdminPaymentsJson/>}/>
                            <Route path="/admin/shipments" element={<AdminShipmentsList/>}/>
                            <Route path="/admin/dispatch_orders" element={<AdminDispatchOrdersList/>}/>
                            <Route path="/admin/dispatch_orders/json" element={<AdminDispatchOrdersJson/>}/>
                            <Route path="/admin/shipments/json" element={<AdminShipmentsJson/>}/>
                            <Route path="/admin/edit_about" element={<AdminEditAbout/>}/>
                            <Route path="/admin/edit_rules" element={<AdminEditShopRules/>}/>
                            <Route path="/admin/edit_gift_card" element={<AdminEditGiftCard/>}/>
                            <Route path="/admin/add_gift_card" element={<AdminAddGiftCard/>}/>
                            <Route path="/admin/edit_gift_card_rules" element={<AdminEditGiftCardRules/>}/>
                            <Route path="/admin/edit_privacy_policy" element={<AdminEditPrivacyPolicy/>}/>
                            <Route path="/admin/edit_shipment_and_costs" element={<AdminEditShipmentAndCosts/>}/>
                            <Route path="/admin/edit_payment" element={<AdminEditPaymentInfo/>}/>
                            <Route path="/admin/edit_returns" element={<AdminEditReturns/>}/>
                            <Route path="/admin/discounts" element={<AdminDiscountsList/>}/>
                            <Route path="/admin/discounts/:id" element={<AdminEditDiscount/>}/>
                            <Route path="/admin/discounts/add_discount" element={<AdminAddDiscount/>}/>
                            <Route path="/admin/config" element={<AdminConfig/>}/>
                            <Route path="/admin/newsletter" element={<AdminSendNewsletter/>}/>
                            <Route path="/admin/newsletter_history" element={<AdminNewsletterHistory/>}/>
                            <Route path="/admin/newsletter_history/json" element={<AdminNewsletterJson/>}/>
                            <Route path="/admin/subscriptions" element={<AdminSubscriptionsList/>}/>
                            <Route path="/admin/subscriptions/:id" element={<AdminEditSubscription/>}/>
                            <Route path="/admin/subscriptions/json" element={<AdminSubscriptionJson/>}/>
                        </Route>
                    </Route>

                    {/* FALLBACK ROUTE */}
                    <Route path="/*" element={<Layout/>}>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
