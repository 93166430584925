import React, {useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {useGetOrdersQuery} from "../../Orders/orderApiSlice";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import AdminOrderRow from "./AdminOrderRow";
import CustomSelect from "../../../components/Reusable/CustomSelect";
import useConfig from "../../../hooks/useConfig";
import {getOrderStats} from "../../../utils/getOrderStats";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminClientsList = () => {
    const [err, setErr] = useState(null)
    const {CONSTANTS: {ORDER_STATUS_LIST, ORDER_STATUS_TRANSLATION}} = useConfig()
    const [state, setState] = useState({
            orders: null,
            filter:
                {id: 1, name: "processed"},
            quantities: {
                pending: null,
                processed: null
            }
        }
    )

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetOrdersQuery('ordersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if (isSuccess && data) setState(prev => ({
            ...prev,
            quantities: getOrderStats(data),
            orders: data
        }))
    }, [data]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const filteredOrders = useMemo(() => {
        if (!state.orders) return {ids: [], entities: []}
        return state.orders.ids.reduce((acc, id) => {
            if (state.orders.entities[id]?.status === state.filter.name) {
                acc.ids.push(id);
                acc.entities[id] = state.orders.entities[id];
            }
            return acc;
        }, {ids: [], entities: {}});
    }, [state.orders, state.filter])

    const clearError = () => {
        setErr(null);
    };
    const changeFilter = (e) => {
        const name = e.target.dataset.id
        setState(prev => ({
            ...prev,
            filter: ORDER_STATUS_LIST.find(el => el.name === name)
        }));
    }

    let result = null
    if (isLoading) result = <h1> Wczytuję...</h1>
    if (state.orders) {
        const {ids} = filteredOrders
        const rows = ids.map((id, i) => {
            return <AdminOrderRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
            />
        })

        result = (
            <section className="admin__panel__section admin__orders col-18 col-lg-16">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <h1 className="admin__panel__section-title">ZAMÓWIENIA</h1>
                <div className="admin__panel__section__buttons admin__orders__buttons">
                    <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                    <div className="admin__panel__section__buttons-group admin__orders__buttons__group">
                        <button
                            className={state.filter.name === "pending"
                                ? "blue-button pending selected"
                                : "blue-button pending"}
                            onClick={changeFilter}
                            data-id="pending"
                        >
                            {state.quantities.pending ?
                                <span className="notification">{state.quantities.pending}</span> : null}
                            Oczekujące
                        </button>

                        <button
                            className={state.filter.name === "processed"
                                ? "blue-button processed selected"
                                : "blue-button processed"} onClick={changeFilter}
                            data-id="processed"
                        >
                            W realizacji
                            {state.quantities.processed ?
                                <span className="notification">{state.quantities.processed}</span> : null}
                        </button>
                        <div className="select-container">
                            <CustomSelect
                                selected={state.filter}
                                list={ORDER_STATUS_LIST}
                                attribute="filter"
                                attributeId="filter"
                                placeholder="Wybierz filtr"
                                className="filter"
                                setSelected={setState}
                                translation={ORDER_STATUS_TRANSLATION}
                            />
                        </div>

                    </div>
                </div>
                <div className="admin__panel__section__list admin__orders__list ">
                    <div className="admin__panel__section__list-item col-1 list-header">Nr</div>
                    <div className="admin__panel__section__list-item col-2 list-header">Kwota</div>
                    <div className="admin__panel__section__list-item col-4 list-header">Data</div>
                    <div className="admin__panel__section__list-item col-5 product__price list-header ">E-mail</div>
                    <div className="admin__panel__section__list-item col-3 product__kind list-header">Status</div>
                    <div className="admin__panel__section__list-item col-3 list-header">Edycja</div>
                </div>
                {rows}
            </section>
        );
        return result
    }
};

export default AdminClientsList;