import {apiSlice} from "../../app/api/apiSlice";
import {logoutAndReset, setCredentials} from "./authSlice";
import {clearClientCart} from "../Cart/cartSlice";
import {getErrorMessage} from "../../utils/getErrorMessage";

// Extend the apiSlice to include endpoints for authentication
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // Mutation for logging in
        login: builder.mutation({
            // Configuration for the login request
            query: credentials => ({
                url: '/api/auth',
                method: 'POST',
                body: {...credentials},
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            })
        }),

        // Mutation for two factor authentication
        verify: builder.mutation({
            // Configuration for the login request
            query: token => ({
                url: '/api/auth/twofactor',
                method: 'POST',
                body: {...token},
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            })
        }),

        // Mutation for logging out
        sendLogout: builder.mutation({
            // Configuration for the logout request
            query: () => ({
                url: '/api/auth/logout',
                method: 'POST',
            }),
            // Additional logic to handle logout
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data.message);
                    dispatch(logoutAndReset());
                    dispatch(clearClientCart())
                    // Reset the API state after a short delay
                    setTimeout(() => {
                        if (data.success) dispatch(apiSlice.util.resetApiState())
                    }, 100);
                } catch (err) {
                    getErrorMessage(err);
                }
            }
        }),
        // Mutation for refreshing the access token
        refresh: builder.mutation({
            // Configuration for the refresh request
            query: () => ({
                url: '/api/auth/refresh',
                method: 'GET'
            }),
            // Additional logic to handle token refresh
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    // Update the credentials with the new access token
                    const {data} = await queryFulfilled;
                    const {accessToken} = data;
                    dispatch(setCredentials({accessToken}))
                } catch (err) {
                    console.log("Refresh token error", getErrorMessage(err));
                    if (err.error.status === 401) {
                        console.log("authApiSlice: No refresh token logging out...");
                        dispatch(logoutAndReset())
                    }
                }
            }
        }),
    })
})

// Export hooks for the authentication mutations
export const {
    useLoginMutation,
    useVerifyMutation,
    useSendLogoutMutation,
    useRefreshMutation
} = authApiSlice;
