import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {
    useDeleteProductMutation,
    useGetAllProductsQuery,
    useGetProductsQuery,
    useUpdateProductMutation
} from "../../Products/producsApiSlice";
import {convertFilesToBase64} from "../../../utils/convertFilesToBase64";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import PanelSectionCheckBox from "../AdminPanelSection/PanelSectionCheckBox";
import AdminEditGiftCardAddAmount from "./AdminEditGiftCardAddAmount";
import PanelSectionPhotoEdit from "../AdminPanelSection/PanelSectionPhotoEdit";
import PanelSectionTextarea from "../AdminPanelSection/PanelSectionTextarea";
import {validateGiftCardData} from "../../../utils/validateGiftCardData";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminEditGiftCard = () => {
    const [err, setErr] = useState()
    const [formError, setFormError] = useState()
    const [state, setState] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const [check, setCheck] = useState()

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetAllProductsQuery('adminProductsList', {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities ? Object.values(data?.entities).find(el => el.name === "Karta podarunkowa") : undefined,
            ...other
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [updateGiftCard, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateProductMutation()

    const [deleteGiftCard, {
        data: deleteResult,
        isLoading: isDeleteLoading,
        isError: isDeleteError,
        error: deleteError,
        isSuccess: isDeleteSuccess
    }] = useDeleteProductMutation()

    useEffect(() => {
        if (!isSuccess && !data) return
        const giftCardData = {
            ...data,
            selectedFiles: [],
        }
        setIsValid(validateGiftCardData(giftCardData))
        setState(giftCardData)

    }, [data, isSuccess]);

    // Validate order data whenever the order state changes
    useEffect(() => {
        if (!state) return
        setFormError(null);
        setIsValid(validateGiftCardData(state));
    }, [state]);

    useEffect(() => {
        if (isUpdateSuccess || isDeleteSuccess) navigate("/admin")
    }, [updateGiftCard, isUpdateSuccess, deleteGiftCard, isDeleteSuccess]);


    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setErr(errorMessage);
        }

        if (isUpdateError) {
            const errorMessage = updateError?.data?.message || updateError?.message || updateError?.error || 'An error occurred';
            setErr(errorMessage);
        }
        if (isDeleteError) {
            const errorMessage = deleteError?.data?.message || deleteError?.message || deleteError?.error || 'An error occurred';
            setErr(errorMessage);
        }

    }, [error, isError, updateError, isUpdateError, deleteError, isDeleteError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        if (e.target.type === "number") value = Number(value)
        setState(prev => ({...prev, [id]: value}))
    }

    const setCheckBox = (e) => {
        const {checked} = e.target;
        setState(prev => ({
            ...prev,
            display: checked
        }))
    }

    const onDeleteGiftCardClicked = async () => {
        try {
            await deleteGiftCard(state.id)
        } catch (err) {
            const errorMessage = err?.data?.message || err?.message || err?.error || 'An error occurred'
            setErr(errorMessage)
        }
    }
    const onSaveDiscountClicked = async (e) => {
        e.preventDefault()
        // Check if form is valid
        setCheck(true)
        if (!isValid?.all) {
            console.log(isValid, state);
            setFormError('Dane produktu nie są kompletne.');
            console.log('Order data incomplete');
            return;
        }
        const files = state.selectedFiles.filter(el => el !== null)
        const base64Files = await convertFilesToBase64(files)
        const pictures = base64Files.map((file, i) => `${state.name}_${i + 1}.png`);

        const product = {
            ...state,
            pictures,
            attribute: state.attribute.name,
            selectedFiles: base64Files
        }

        try {
            await updateGiftCard(product).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    if (!state) return null
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Karta podarunkowa</h1>

            <div className="admin__panel__section__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                <span className='error-message'>{formError}</span>
                <div className="admin__panel__section__buttons-group">
                    <button
                        className="admin__panel__section__buttons-button red-button"
                        onClick={onDeleteGiftCardClicked}
                    >{isDeleteLoading ? "Loading..." : "Usuń"}
                    </button>
                    <button
                        className="admin__panel__section__buttons-button yellow-button"
                        onClick={onSaveDiscountClicked}
                    >{isUpdateLoading ? "Loading..." : "Zapisz"}
                    </button>
                </div>
            </div>

            <form className="admin__panel__section__from">

                <PanelSectionCheckBox
                    checked={state.display}
                    title="AKTYWNA"
                    name="active"
                    label="Karta aktywna"
                    setChecked={setCheckBox}
                />
                <AdminEditGiftCardAddAmount
                    isValid={isValid?.amounts}
                    check={check}
                    state={state}
                    setState={setState}

                />
                <PanelSectionPhotoEdit
                    state={state}
                    check={check}
                    isValid={isValid?.selectedFiles}
                    setFormError={setFormError}
                    setErr={setErr}
                    selectedFiles={state.selectedFiles}
                    setSelectedFiles={setState}
                />
                <PanelSectionTextarea
                    isValid={isValid?.description}
                    check={check}
                    value={state.description}
                    setInput={setInput}
                    title="OPIS"
                    name="description"
                    placeholder="Dodaj opis"
                />
            </form>
        </section>
    );
};

export default AdminEditGiftCard;