import {useEffect, useState} from "react";
import {noBackgroundScroll} from "../../utils/noBackgroundScroll";
import useConfig from "../../hooks/useConfig";

const TwoFactorVerify = ({code, setTwoFactor, handleVerify}) => {
    const [formError, setFormError] = useState(null);
    const {REG_EX} = useConfig();

    useEffect(() => {
        noBackgroundScroll("add");
        code.current.focus();

        return () => {
            noBackgroundScroll("remove");
        };
    }, []);

    const handleClose = () => {
        setTwoFactor((prev) => ({
            ...prev,
            showForm: false,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        if (!REG_EX.TWO_FACTOR_CODE.test(code.current.value)) {
            setFormError("Nieprawidłowy kod");
            return;
        }

        handleVerify();
        code.current.value = "";
        handleClose();
    };

    const handleOnChange = () => {
        if (formError) setFormError(null);
    };

    return (
        <div className="overlay">
            <form className="code col-18 col-md-8" onSubmit={handleSubmit}>
                <div className="code-content">
                    <h2 className="code-content-title">WPROWADŹ KOD</h2>
                    <input
                        ref={code}
                        className="col-sm-12 code-content-input"
                        type="text"
                        placeholder="Wprowadź 6-cyfrowy kod"
                        onChange={handleOnChange}
                    />
                </div>
                <span className="error-message">{formError}</span>
                <div className="code-buttons row">
                    <button
                        type="button"
                        className="col-sm-6 col-md-8 inverted-link-button"
                        onClick={handleClose}
                    >
                        Zamknij
                    </button>
                    <button
                        type="submit"
                        className="col-sm-6 col-md-8 link-button"
                    >
                        Wyślij
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TwoFactorVerify;
