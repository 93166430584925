import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";
import {useGetMarkdownQuery} from "../../app/appApiSlice";
import DOMPurify from "dompurify";
import {marked} from "marked";
import React, {useEffect, useState} from "react";
import ErrorPopup from "../../components/Reusable/ErrorPopup";

const ShipmentAndCostsInfo = () => {

    const {
            data: shipment,
            isLoading: isShipmentLoading,
            isSuccess: isShipmentSuccess,
            isError: isShipmentError,
            error: shipmentError
        } = useGetMarkdownQuery('shipment_and_costs.md')
    ;const {
        data: payment,
        isLoading, isPaymentLoading,
        isSuccess: isPaymentSuccess,
        isError: isPaymentError,
        error: paymentError
    } = useGetMarkdownQuery('payment.md');

    const [err, setErr] = useState()

    useEffect(() => {
        if (isShipmentError) {
            const errorMessage = shipmentError?.data?.message || shipmentError?.message || shipmentError?.error || 'An error occurred';
            setErr(errorMessage);
        }
        if (isPaymentError) {
            const errorMessage = paymentError?.data?.message || paymentError?.message || paymentError?.error || 'An error occurred';
            setErr(errorMessage);
        }
    }, [shipmentError, isShipmentError, paymentError, isPaymentError]);

    const clearError = () => {
        setErr(null);
    };


    let shipmentContent = null
    let paymentContent = null

    if (isShipmentSuccess && shipment && isPaymentSuccess && payment) {
        shipmentContent =
            <div className="md-body"
                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(shipment.content))}}/>
        paymentContent =
            <div className="md-body"
                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(payment.content))}}/>
    }
    return (
        <>
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className='shipment__info__content'>
                    <div className='shipment__info__content__column shipment__info__content__column-left'>
                        <h2 className="shipment__info__content__column-title">Koszty i metody dostawy</h2>
                        {isShipmentLoading
                            ? <h3>Loading...</h3>
                            : shipmentContent}
                    </div>
                    <div className='shipment__info__content__column shipment__info__content__column-right'>
                        <h2 className="shipment__info__content__column-title">Metody płatności</h2>
                        {isPaymentLoading
                            ? <h3>Loading...</h3>
                            : paymentContent}
                    </div>
                </div>

            </InnerSection>
            <NewsLetter/>
        </>
    );
};

export default ShipmentAndCostsInfo;