import axios from 'axios';

export const downloadShipmentLabel = async (LabelUrl, token, orderId, number) => {
    const response = await axios.post(
        `${LabelUrl}`,
        {RTK_TOKEN: process.env.REACT_APP_RTK_TOKEN, orderId},
        {

            headers: {
                'authorization': `Bearer ${token}`,
                'x-custom-token': process.env.REACT_APP_API_TOKEN
            },
            responseType: 'blob' // Ensure response is handled as binary
        }
    );

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

    // Create a link element to download the file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `shipment_label_${number}_${orderId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

