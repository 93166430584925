import React, {useEffect, useState} from 'react';
import {noBackgroundScroll} from "../../../utils/noBackgroundScroll";
import CheckBox from "../../../components/Reusable/CheckBox";

const AdminEditPhotoColorModal = ({state, index, setEditPhotoColor, setSelectedFiles}) => {
    const [selectedColor, setSelectedColor] = useState("")
    const {name, quantities: {list}, selectedFiles} = state
    const [error, setError] = useState(null)


    useEffect(() => {
        if (!index || !selectedFiles.length) {
            setError("Coś poszło nie tak")
            console.error(`No image files or no index.\n index: ${index}\n${selectedFiles}`)
            return
        }

        const file = selectedFiles[index]
        const name = file.name.split("-")[1]
        if (["general", ...list].includes(name)) setSelectedColor(name)
    }, [index, selectedFiles]);


    useEffect(() => {
        noBackgroundScroll("add")
        // Cleanup effect on component unmount
        return () => {
            noBackgroundScroll("remove")
        };
    }, []);

    const closeModal = (e) => {
        e.preventDefault()
        setEditPhotoColor({
            show: false,
            index: null
        })
    }

    const accept = (e) => {
        e.preventDefault()
        if (!selectedColor) return
        let file = selectedFiles[index]
        if (!file) {
            setError("Coś poszło nie tak!")
            console.error(`No image file. \n file: ${file}\n index: ${index}\nselectedFiles: ${selectedFiles}`)
            return;
        }
        file = new File([file], `${name}-${selectedColor}-${index}`, {type: file.type})
        setSelectedFiles(prev => {
            const newSelectedFiles = [
                ...prev.selectedFiles]
            newSelectedFiles[index] = file
            return {
                ...prev,
                selectedFiles: newSelectedFiles
            }
        })
        // Close modal
        setEditPhotoColor({
            show: false,
            index: null
        })
    }

    const colorList = list.map((el, i) => <CheckBox
        key={i}
        checked={selectedColor === el}
        name={el}
        setChecked={() => setSelectedColor(el)}
        label={el} className="photo__upload__modal__list-checkbox"
    />)


    return (
        <div className="overlay" onClick={closeModal}>
            <div
                className="photo__upload__modal"
                onClick={(e) => e.stopPropagation()}>
                <div className="photo__upload__modal__list">
                    {colorList}
                    <CheckBox
                        key="default"
                        checked={selectedColor === "general"}
                        name="general"
                        setChecked={() => setSelectedColor("general")}
                        label="Bez koloru" className="photo__upload__modal__list-checkbox"
                    />
                </div>
                {error ? <span className='error-message'>{error}</span> : null}
                <div className="photo__upload__modal-buttons">
                    <button
                        type="button"
                        className="admin-button-yellow"
                        onClick={accept}
                        disabled={!selectedColor || error}
                    >Zapisz
                    </button>
                    <button
                        type="button"
                        className="admin-button"
                        onClick={closeModal}
                    >Zamknij
                    </button>
                </div>

            </div>
        </div>
    );
};

export default AdminEditPhotoColorModal;