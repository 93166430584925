export const adminValidateClient = (client, CONFIG) => {
    const {REG_EX, CONSTANTS: {COUNTRIES}} = CONFIG

    const validateField = (field, regex) => regex.test(field);
    const validateOption = (option, list) => list.some(el => el.name === option);

    const COUNTRY = client.addresses.country.name.replace(" ", "_").toUpperCase()
    const BILLING_COUNTRY = client.addresses.billingAddress.country.name.replace(" ", "_").toUpperCase()


    const isValid = {
        name: validateField(client.name, REG_EX.NAME),
        surname: validateField(client.surname, REG_EX.NAME),
        email: validateField(client.email, REG_EX.EMAIL),
        country: validateOption(client.addresses.country.name, COUNTRIES),
        street: client.addresses.street === "" || validateField(client.addresses.street, REG_EX.STREET_NAME),
        buildingNumber: client.addresses.buildingNumber === "" || validateField(client.addresses.buildingNumber, REG_EX.STREET_NAME),
        postCode: client.addresses.postCode === "" || validateField(client.addresses.postCode, REG_EX[COUNTRY].POST_CODE),
        city: client.addresses.city === "" || validateField(client.addresses.city, REG_EX.EU_CITY_NAME),
        billingAddress: {
            name: validateField(client.addresses.billingAddress.name, REG_EX.NAME),
            surname: validateField(client.addresses.billingAddress.surname, REG_EX.NAME),
            country: validateOption(client.addresses.billingAddress.country.name, COUNTRIES),
            street: validateField(client.addresses.billingAddress.street, REG_EX.STREET_NAME),
            buildingNumber: client.addresses.billingAddress.buildingNumber === "" || validateField(client.addresses.billingAddress.buildingNumber, REG_EX.STREET_NAME),
            postCode: validateField(client.addresses.billingAddress.postCode, REG_EX[BILLING_COUNTRY].POST_CODE),
            city: validateField(client.addresses.billingAddress.city, REG_EX.EU_CITY_NAME)
        }
    }

    const isDataValid = {...isValid}
    delete isDataValid.billingAddress
    let all = Object.values(isDataValid).every(el => el === true)

    if (!client.addresses.billingAddress.theSame) {
        all = all && Object.values(isValid.billingAddress).every(el => el === true)
    }

    return {...isValid, all}
}