import React from "react";
import ShipmentSection from "./ShipmentSection";
import SubscribeSection from "./SubscribeSection";
import DataSection from "./DataSection";
import PaymentSection from "./PaymentSection";
import BillingAddressSection from "./BillingAddressSection";
import {useSelector} from "react-redux";
import CheckBox from "../../../components/Reusable/CheckBox";

const OrderForm = ({order, setOrder, check, isValid, isLoading, formError, handleSubmit}) => {
    const {allInStock} = useSelector((state) => state.cart.cart);
    const setConsent = (e) => {
        setOrder(prev => ({...prev, consent: e.target.checked}))
    }

    const result = (
        <form
            className="col-18 col-lg-8 order__content__form"
            onSubmit={handleSubmit}>
            <SubscribeSection
                order={order}
                setOrder={setOrder}
                check={check}
                isValid={isValid}
            />
            <DataSection
                order={order}
                setOrder={setOrder}
                check={check}
                isValid={isValid}
                title={"Dane dostawy"}
            />
            <ShipmentSection
                order={order}
                setOrder={setOrder}
                check={check}
                isValid={isValid}
            />
            <PaymentSection
                order={order}
                setOrder={setOrder}
                check={check}
                isValid={isValid}
            />
            <BillingAddressSection
                order={order}
                setOrder={setOrder}/>
            {!order?.billingAddress?.theSame && <DataSection
                order={order?.billingAddress}
                setOrder={setOrder}
                check={check}
                isValid={isValid?.billingAddress}
                title={"Podaj dane rozliczeniowe"}
            />}
            <CheckBox
                name="consent-checkbox"
                className={!check ? 'order__content__form-consent' : isValid.isConsentValid ? 'order__content__form-consent' : 'order__content__form-consent not-valid'}
                label="Aceptuję regulamin oraz wyrażam zgodę na prztwarzanie moich danych osobowych w celu realizacji zamówienia."
                checked={order?.consent}
                setChecked={setConsent}
            />
            <div className="button-group">
                <span className='error-message'>{formError}</span>
                <button
                    className="link-button"
                    type="submit"
                    disabled={!allInStock || isLoading}
                >{isLoading ? "Realizuję zamówienie..." : "ZAMAWIAM I PŁACĘ"}
                </button>
            </div>
        </form>
    )
    return result
};


export default OrderForm;