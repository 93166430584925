import React, {useEffect, useRef, useState} from 'react';
import InnerSection from "../../../components/Reusable/InnerSection";
import {ReactComponent as Close} from "../../../images/close.svg";
import {Link} from "react-router-dom";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import SubmitButton from "../../../components/Reusable/SubmitButton";
import {useResetPasswordMutation} from "../clientApiSlice";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import useConfig from "../../../hooks/useConfig";

const ResetPassword = () => {
    const [err, setErr] = useState(null)
    const [formError, setFormError] = useState(null)
    const [check, setCheck] = useState(false)
    const emailRef = useRef()
    const [isValid, setIsValid] = useState({email: false})
    const {REG_EX} = useConfig()
    const [resetPassword, {
        data: resetData,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useResetPasswordMutation();

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        setCheck(false)
        setFormError(null);
        setIsValid({
            email: REG_EX.EMAIL.test(emailRef.current?.value),
        })
    }

    const onResetPasswordClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!isValid.email) {
            setFormError("Podany adres email jest niepoprawny")
            return
        }
        try {
            await resetPassword(emailRef.current?.value).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    let result = (
        <InnerSection>
            <div className="client__edit">
                <Link
                    className="close-button"
                    to={'/login'}
                ><Close/></Link>
                <div className="col-18 client__panel__content">
                    {err && <ErrorPopup message={err} onClose={clearError}/>}
                    <form
                        className="client__edit__form col-18 col-lg-9"
                        onSubmit={onResetPasswordClicked}>
                        <h2 className="client__edit__form-title">ZRESETUJ HASŁO</h2>

                        <input
                            ref={emailRef}
                            className={!check ? 'custom-input col-18' : isValid.email ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            type='email'
                            name='email'
                            data-id="email"
                            placeholder='E-mail'
                            onChange={setInput}
                        />
                        <SubmitButton
                            className="link-button"
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                            errorMessage={formError}
                            text="WYŚLIJ"
                            isLoadingText="WYSYŁAM..."
                        />
                    </form>
                </div>
            </div>
        </InnerSection>
    );

    if (isSuccess) {
        result = (
            <InnerSection>
                <h1>Zresetuj hasło</h1>
                <p>Jeśli mamy konto z tym adresem e-mail, wysłaliśmy Ci wiadomość z linkiem do zmiany hasła.</p>
                <p>Postępuj zgodnie z instrukcjami zawartymi w wiadomości.</p>
            </InnerSection>
        )
    }
    return result;
};

export default ResetPassword;