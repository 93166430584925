import React, {useState} from 'react';
import PicturePreview from "./PicturePreview";
import useConfig from "../../../hooks/useConfig";

const ProductPictures = ({product, color}) => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const [selectedImage, setSelectedImage] = useState({
        url: null,
        fileName: null
    }); // Track the clicked image

    // Function to handle clicking on an image
    const handleImageSelect = (fileName) => {
        setSelectedImage({
            fileName,
            url: `${SERVER_ADDRESS}/img/${product.id}/${fileName}`
        });
    };

    // Function to close the modal
    const closeModal = () => {
        setSelectedImage({
            fileName: null,
            url: null
        }); // Reset selected image
    };

    //Filtering out other colors
    const filteredPictures = product.pictures
        .filter(el => ["general", color].includes(el.split("-")[1]))
        .sort((a, b) => {
            const aType = a.split("-")[1];
            const bType = b.split("-")[1];

            if (aType === color && bType === "general") return -1;
            if (aType === "general" && bType === color) return 1;
            return 0; // No change if types are the same
        });

    return (
        <div className="product__pictures col-18 col-md-9 col-hd-6">
            {filteredPictures.map(fileName => (
                <img key={fileName}
                     className='product__picture previewable'
                     src={`${SERVER_ADDRESS}/img/${product.id}/${fileName}`}
                     alt={fileName}
                     onClick={() => handleImageSelect(fileName)} //
                />
            ))}

            {selectedImage.fileName && <PicturePreview
                product={product}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
                closeModal={closeModal}/>
            }
        </div>
    );
};

export default ProductPictures;
