import React, {useEffect, useState} from 'react';
import {useAddNewProductMutation} from "../../Products/producsApiSlice";
import {Link, useNavigate} from "react-router-dom";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import AdminEditProductAddColor from "./AdminEditProductAddColor";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import PanelSectionSelect from "../AdminPanelSection/PanelSectionSelect";
import PanelSectionTextarea from "../AdminPanelSection/PanelSectionTextarea";
import {convertFilesToBase64} from "../../../utils/convertFilesToBase64";
import {productObject} from "./productObject";
import {validateProductData} from "../../../utils/validateProductData";
import PanelSectionPhotoEdit from "../AdminPanelSection/PanelSectionPhotoEdit";
import useGetLocations from "../../../hooks/useGetLocations";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminAddProduct = () => {
    const [err, setErr] = useState()
    const [state, setState] = useState(productObject)
    const [isValid, setIsValid] = useState(null)
    const [formError, setFormError] = useState(null)
    const [check, setCheck] = useState(false);
    const navigate = useNavigate()
    const CONFIG = useConfig()
    const locations = useGetLocations()
    locations.pop()

    const [saveProduct, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewProductMutation();

    useEffect(() => {
        setFormError(null);
        setIsValid(validateProductData(state, CONFIG));
    }, [state]);

    useEffect(() => {
        if (isSuccess) navigate("/admin/products")
    }, [isSuccess, data]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        if (e.target.type === "number") value = Number(value)
        setState(prev => ({...prev, [id]: value}))
    }

    const onAddProductClicked = async (e) => {
        e.preventDefault()
        // Check if form is valid
        setCheck(true)
        if (!isValid?.all) {
            setFormError('Dane produktu nie są kompletne.');
            console.log('Order data incomplete');
            return;
        }
        const files = state.selectedFiles.filter(el => el !== null)
        const pictures = files.map((file, i) => file.name);
        const base64Files = await convertFilesToBase64(files)

        const product = {
            ...state,
            name: state.name.trim(),
            pictures,
            attribute: state.attribute.name,
            kind: state.kind.name,
            selectedFiles: base64Files
        }
        try {
            await saveProduct(product).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista produktów"}
    </Link>)

    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Dodaj produkt</h1>
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <button
                    className=" admin__panel__section__buttons-button yellow-button"
                    onClick={onAddProductClicked}
                >{isLoading ? "Loading..." : "Ddodaj"}
                </button>
            </div>
            <form className="admin__panel__section__from">

                <PanelSectionInput
                    isValid={isValid?.name}
                    check={check}
                    state={state}
                    type="text"
                    setInput={setInput}
                    title="NAZWA"
                    name="name"
                    placeholder="Nazwa"
                />

                <PanelSectionSelect
                    isValid={isValid?.kind}
                    check={check}
                    title="RODZAJ"
                    placeholder="Wybierz rodzaj"
                    attribute="kind"
                    selected={state.kind}
                    setState={setState}
                    list={CONFIG.CONSTANTS.PRODUCT_KIND}
                />

                <PanelSectionSelect
                    isValid={isValid?.attribute}
                    check={check}
                    title="WYŚWIETL"
                    placeholder="Wybierz opcję"
                    attribute="attribute"
                    selected={state.attribute}
                    setState={setState}
                    list={CONFIG.CONSTANTS.PRODUCT_ATTRIBUTES}
                />

                <AdminEditProductAddColor
                    isValid={isValid?.quantities}
                    check={check}
                    state={state}
                    setInput={setState}
                />

                <PanelSectionPhotoEdit
                    isValid={isValid?.selectedFiles}
                    check={check}
                    state={state}
                    setSelectedFiles={setState}
                    setFormError={setFormError}
                    setErr={setErr}
                />

                <PanelSectionInput
                    isValid={isValid?.price}
                    check={check}
                    state={state}
                    type="number"
                    setInput={setInput}
                    title="CENA"
                    name="price"
                    placeholder="Cena"
                />

                <PanelSectionTextarea
                    isValid={isValid?.description}
                    check={check}
                    state={state}
                    setInput={setInput}
                    title="OPIS"
                    name="description"
                    placeholder="Dodaj opis"
                />
                <PanelSectionTextarea
                    isValid={isValid?.material}
                    check={check}
                    state={state}
                    setInput={setInput}
                    title="SKłAD I PIELĘGNACJA"
                    name="material"
                    placeholder="Dodaj opis składi i pielęgnacji"
                />
                <PanelSectionTextarea
                    isValid={isValid?.sizeChartInfo}
                    check={check}
                    state={state}
                    setInput={setInput}
                    title="TABELA ROZMIARÓW"
                    name="sizeChartInfo"
                    placeholder="Dodaj opis tabeli rozmiarów"
                />
            </form>
        </section>
    );
};

export default AdminAddProduct;