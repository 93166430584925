import React from 'react';
import DOMPurify from "dompurify";

const AdminPreviewNewsletter = ({newsletter, toggleShowPreview}) => {
    return (
        <div className="overlay admin__newsletter__list__preview">
            <div className="admin__newsletter__list__preview__content col-18 col-md-12">
                <div className="buttons">
                    <button
                        className="blue-button"
                        onClick={() => toggleShowPreview()}
                    >Zamknij
                    </button>
                </div>
                <div id="body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsletter.body)}}/>
            </div>
        </div>
    );
};

export default AdminPreviewNewsletter;