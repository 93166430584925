import React, {useEffect} from 'react';
import {noBackgroundScroll} from "../../utils/noBackgroundScroll";

const TwoFactorEnable = ({qrCode, close}) => {
    useEffect(() => {
        noBackgroundScroll("add")
        // Cleanup the effect when the component is unmounted
        return () => {
            noBackgroundScroll("remove");
        };
    }, []);

    const onCloseClicked = () => {
        close(prev => ({
            ...prev,
            qrCode: null
        }))
    }
    return (
        <div className="overlay">
            <div className="code">
                <div className="code-content">
                    <h2
                        className="code-content-title">
                        Zeskanuj ten kod QR przy pomocy Google Authenticator:</h2>
                    <img
                        className="code-content-qr"
                        src={qrCode.qrCodeData} alt="2FA QR Code"/>
                </div>
                <button
                    className="col-sm-6 col-md-8 link-button"
                    onClick={onCloseClicked}
                >Zamknij
                </button>
            </div>
        </div>
    );
};

export default TwoFactorEnable;