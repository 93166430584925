import InPostGeoWidget from "../../../components/Inpost/InpostGeoWidget";
import {useState} from "react";
import useConfig from "../../../hooks/useConfig";

const ShipmentSection = ({check, isValid, order, setOrder}) => {
    const isVisible = isValid.data
    const [show, setShow] = useState(false)
    const {shipment} = order
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()


    const handleChange = (e) => {
        setOrder(prev => ({
            ...prev,
            shipment: e.target.id
        }))
    }

    const handlePointSelected = (point) => {
        setOrder(prev => ({
            ...prev,
            selectedPoint: point
        }));
        setShow(!show)
    };

    const toggleShowInPostSelect = () => {
        setShow(!show)
    }
    return (
        <div className="form-section">
            <h2 className="form-subtitle">Metoda wysyłki</h2>
            {!isVisible && <div
                className={!check ? 'form-element' : isValid?.isShipmentValid ? 'form-element' : 'form-element not-valid'}
                tabIndex={0}
            >
                <span>Wprowadź dane adresowe, aby wybrać metodę wysyłki.</span>
                <span className={"select_decor"}></span>

            </div>}
            {isVisible && <>
                <div
                    className={!check ? 'radio-element col-18 in_post-select' : isValid?.isSelectedPointValid ? 'radio-element col-18 in_post-select' : 'radio-element col-18 not-valid in_post-select'}>
                    <input
                        checked={order.shipment === 'InPostPaczkomat'}
                        type="radio"
                        name="shipmentGroup"
                        id="InPostPaczkomat"
                        tabIndex="0"
                        onChange={handleChange}
                    />
                    <label
                        className="radio-label"
                        htmlFor="InPostPaczkomat">
                        <img
                            className='in-post-banner'
                            src={`${SERVER_ADDRESS}/img/InPostPaczkomatLogo.png`}
                        />
                    </label>
                    {shipment === 'InPostPaczkomat'
                        ?
                        <button type='button' className='link-button' onClick={toggleShowInPostSelect}>Wybierz</button>
                        : null
                    }

                </div>
                <div
                    className={!check ? 'radio-element col-18' : isValid?.isShipmentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>
                    <input
                        checked={order.shipment === 'InPostKurier'}
                        type="radio"
                        name="shipmentGroup"
                        id="InPostKurier"
                        tabIndex="0"
                        onChange={handleChange}
                    />
                    <label
                        className="radio-label"
                        htmlFor="InPostKurier">
                        <img
                            className='in-post-banner'
                            src={`${SERVER_ADDRESS}/img/Wide.png`}
                        />
                    </label>
                </div>
                {show
                    ? <InPostGeoWidget
                        onPointSelected={handlePointSelected}
                        show={show}
                        setShow={setShow}
                    />
                    : null
                }
                {order.selectedPoint && (
                    <div className='selected-point'>
                        <h2>Wybrany paczkomat:</h2>
                        <p>{order.selectedPoint.name}</p>
                        <p>{order.selectedPoint.address.line1}</p>
                        <p>{order.selectedPoint.address.line2}</p>
                    </div>
                )}
                {/*<div*/}
                {/*    className={!check ? 'radio-element col-18' : isValid?.isShipmentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>*/}
                {/*    <input*/}
                {/*        checked={order.shipment === 'OrlenPaczka'}*/}
                {/*        type="radio"*/}
                {/*        name="shipmentGroup"*/}
                {/*        id="OrlenPaczka"*/}
                {/*        onChange={handleChange}*/}
                {/*    />*/}
                {/*    <label*/}
                {/*        className="radio-label"*/}
                {/*        htmlFor="OrlenPaczka">*/}
                {/*        Orlen Paczka</label>*/}
                {/*</div>*/}
            </>}
        </div>
    )
}

export default ShipmentSection