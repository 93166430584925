import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";
import {removeAllItems, selectCart, setIsOrderLoading, updateCartAfterAction} from "../Cart/cartSlice";

const ordersAdapter = createEntityAdapter({})
const initialState = ordersAdapter.getInitialState()

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrderById: builder.query({
            query: (id) => ({
                    url: `/api/orders/${id}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError
                    }
                }
            ),
            // keepUnusedDataFor: 5,
            transformResponse: responseData => {
                responseData.id = responseData._id;
                return responseData;
            },
            providesTags: (result, error, arg) => [{type: 'Order', id: result?.id}]
        }),
        addNewOrder: builder.mutation({
            query: (initialOrderData) => ({
                url: '/api/orders',
                method: 'POST',
                body: {
                    ...initialOrderData
                }
            }),
            invalidatesTags: [
                {type: 'Order', id: 'LIST'},
                {type: 'Product', id: 'LIST'}
            ],
            async onQueryStarted(arr, {dispatch, queryFulfilled, getState}) {

                // In order to prevent validating the cart by productApiSlice
                dispatch(setIsOrderLoading(true))
                try {
                    await queryFulfilled
                    const {clientId} = selectCart(getState())
                    clientId
                        ? dispatch(updateCartAfterAction(removeAllItems()))
                        : dispatch(removeAllItems());
                    dispatch(setIsOrderLoading(false))
                } catch (err) {
                    dispatch(setIsOrderLoading(false))
                    console.log(err.error?.data?.message || err.error || err);
                }
            }
        }),
        getOrders: builder.query({
            query: () => ({
                    url: '/api/admin/orders',
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError
                    }
                }
            ),
            transformResponse: responseData => {
                const loadedOrders = responseData.map(order => {
                    order.id = order._id
                    return order
                }).sort((prev, curr) => curr.number - prev.number);
                return ordersAdapter.setAll(initialState, loadedOrders)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Order', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Order', id}))
                    ]
                } else return [{type: 'Order', id: 'LIST'}]
            }
        }),
        updateOrder: builder.mutation({
            query: (initialOrderData) => ({
                url: '/api/admin/orders',
                method: 'PATCH',
                body: {
                    ...initialOrderData
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Order', id: arg.id}
            ]
        }),
        deleteOrder: builder.mutation({
            query: (id) => ({
                url: '/api/admin/orders',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Order', id: arg.id}
            ]
        }),
        initiatePayment: builder.mutation({
            query: (initialPaymentData) => ({
                url: '/api/payments/initiate',
                method: 'POST',
                body: {...initialPaymentData}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Order', id: arg.id}
            ]
        })
    })
})

export const {
    useGetOrdersQuery,
    useGetOrderByIdQuery,
    useAddNewOrderMutation,
    useUpdateOrderMutation,
    useDeleteOrderMutation,
    useInitiatePaymentMutation
} = ordersApiSlice

// returns the query object
export const selectOrdersResult = ordersApiSlice.endpoints.getOrders.select()

// creates memoized selector
const selectOrdersData = createSelector(
    selectOrdersResult,
    ordersResult => ordersResult.data //normalized state object with ids & entities
)

export const {
    selectAll: selectAllOrders,
    selectById: selectOrderById,
    selectIds: selectOrderIds
    // pass in a selector that returns the orders slice of state
} = ordersAdapter.getSelectors(state => selectOrdersData(state) ?? initialState)