import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditAbout = () => {
    return <AdminEditMarkdown
        fileName="about.md"
        navigateAfter="/about"
        title="O nas - EDYCJA"
        className="about__content-body"
    />
};

export default AdminEditAbout;