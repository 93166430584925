import React from 'react';
import {ReactComponent as Add} from "../../../images/add.svg";

const ProductDetail = ({name, title, checked, handleToggleDetail, content}) => {
    return (
        <div className='col-12 product__detail'>
            <h2
                className='product__detail-title'
                id={name}
                onClick={handleToggleDetail}
            >{title}</h2>
            <input
                type="checkbox"
                name={name}
                id={name}
                checked={checked}
                onChange={handleToggleDetail}
            />
            <Add className="show"/>
            {content}
        </div>
    );
};

export default ProductDetail;