import React, {useEffect} from 'react';
import {noBackgroundScroll} from "../../utils/noBackgroundScroll";


const InPostGeoWidget = ({onPointSelected, show, setShow}) => {
    useEffect(() => {
        // Dynamically load the GeoWidget stylesheet
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        // link.href = 'https://geowidget.inpost.pl/inpost-geowidget.css';
        link.href = 'https://sandbox-easy-geowidget-sdk.easypack24.net/inpost-geowidget.css';
        document.head.appendChild(link);

        // Dynamically load the GeoWidget script
        const script = document.createElement('script');
        // script.src = 'https://geowidget.inpost.pl/inpost-geowidget.js';
        script.src = 'https://sandbox-easy-geowidget-sdk.easypack24.net/inpost-geowidget.js';
        script.defer = true;
        script.onload = () => {
            const geoWidget = document.getElementById('geowidget');

            // Add an event listener to handle point selection
            document.addEventListener('onpointselect', (event) => {
                const selectedPoint = event.detail;
                onPointSelected(selectedPoint); // Update the state with the selected point
                if (onPointSelected) {
                    onPointSelected(selectedPoint); // Trigger the parent callback if provided
                }
            });

            // After the script is loaded, initialize the GeoWidget
            geoWidget.addEventListener('inpost.geowidget.init', (event) => {
                const api = event.detail.api;
                // You can call any API methods after widget initialization
                // api.changePosition({longitude: 20.318968, latitude: 49.731131}, 16);
            });
        };
        document.body.appendChild(script);

        // Cleanup the resources when the component unmounts
        return () => {
            document.head.removeChild(link);
            document.body.removeChild(script);
        };
    }, [onPointSelected]); // Add onPointSelected as a dependency

    useEffect(() => {
        noBackgroundScroll("add")
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') setShow(!show)
        });

        // Cleanup effect on component unmount
        return () => {
            noBackgroundScroll("remove")
            window.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') setShow(!show)
            });
        };
    }, []);


    return (
        <div
            className="overlay"
            onClick={() => setShow(!show)}
        >
            <div className='geo-widget'>
                <inpost-geowidget
                    id="geowidget"
                    onpoint="onpointselect"
                    token={process.env.REACT_APP_INPOST_GEO_WIDGET_TOKEN}
                    language="pl"
                    config="parcelcollect"
                ></inpost-geowidget>
            </div>
        </div>
    );
};

export default InPostGeoWidget;
