import React, {useEffect, useState} from 'react';
import ClientPanelHeader from "../ClientPanelHeader";
import {useDeleteClientMutation, useGetClientQuery} from "../../clientApiSlice";
import useAuth from "../../../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as Close} from '../../../../images/close.svg';
import ErrorPopup from "../../../../components/Reusable/ErrorPopup";
import CheckBox from "../../../../components/Reusable/CheckBox";
import SubmitButton from "../../../../components/Reusable/SubmitButton";
import InnerSection from "../../../../components/Reusable/InnerSection";
import {getErrorMessage} from "../../../../utils/getErrorMessage";


const RemoveAccount = () => {
    const {email} = useAuth()
    const [err, setErr] = useState(false)
    const [data, setData] = useState({
        confirm: false
    })
    const [formError, setFormError] = useState("")
    const navigate = useNavigate()

    const {
        data: client,
        isLoading: isClientLoading,
        isSuccess: isClientSuccess,
        isError: isClientError,
        error: clientError
    } = useGetClientQuery(email, {
        skip: !email,
    });

    const [deleteClient, {
        data: deletedClient,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useDeleteClientMutation(email, {
        skip: !email,
    })

    useEffect(() => {
        if (isSuccess) {
            navigate("/login")
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const setCheckBox = (value) => {
        setFormError(null)
        setData(prev => ({...prev, confirm: !prev.confirm}))
    }

    const onSaveDataClicked = async (e) => {
        e.preventDefault()
        if (!data.confirm) {
            setFormError("Potwierdź usunięcię konta")
        }
        try {
            await deleteClient(client.id).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }
    let result

    result = (
        <div className="client__edit">
            <ClientPanelHeader client={client}/>
            <Link
                className="close-button"
                to={'/account/edit/'}
            ><Close/></Link>
            <div className="col-18 client__panel__content">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <form
                    className="client__edit__form col-18 col-lg-9"
                    onSubmit={onSaveDataClicked}>
                    <p>Czy na pewno chcesz trwale usunąć swoje konto?</p>
                    <CheckBox
                        name="confirm"
                        label="Usuń konto."
                        checked={data.confirm}
                        setChecked={setCheckBox}
                    />
                    <SubmitButton
                        className="link-button"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                        errorMessage={formError}
                        text="USUŃ"
                        isLoadingText="USUWAM..."
                    />
                </form>
            </div>
        </div>
    );
    return result;
};

export default RemoveAccount;