import axios from 'axios';

export const downloadDispatchOrderLabel = async (printoutUrl, token, dispatchOrderId, number) => {
    const response = await axios.post(
        `${printoutUrl}`,
        {RTK_TOKEN: process.env.REACT_APP_RTK_TOKEN, dispatchOrderId},
        {

            headers: {
                'authorization': `Bearer ${token}`,
                'x-custom-token': process.env.REACT_APP_API_TOKEN
            },
            responseType: 'blob' // Ensure response is handled as binary
        }
    );

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

    // Create a link element to download the file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `dispatch_order_printout_${number}_${dispatchOrderId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};
