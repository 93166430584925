import {useEffect, useState} from 'react';
import useAuth from "../../../hooks/useAuth";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import ClientPanelHeader from "./ClientPanelHeader";
import {Link, useParams} from "react-router-dom";
import SummaryProduct from "../../Orders/OrderSummary/SummaryProduct";
import {parseISO, format, addDays, isAfter} from "date-fns"
import {useGetClientQuery} from "../clientApiSlice";
import {useGetOrderByIdQuery} from "../../Orders/orderApiSlice";
import {ReactComponent as Close} from '../../../images/close.svg';
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";


const ClientPanelOrderView = () => {
    const {email} = useAuth()
    const [err, setErr] = useState(null);
    const {id} = useParams();
    const {
        CONSTANTS: {
            ORDER_STATUS_TRANSLATION,
            SHIPMENT_OPTION_TRANSLATION,
            SINGLE_SHIPMENT_STATUS_TRANSLATION,
            SINGLE_PAYMENT_STATUS_TRANSLATION
        }
    } = useConfig()
    const {
        data: client,
        isLoading: isClientLoading,
        isSuccess: isClientSuccess,
        isError: isClientError,
        error: clientError
    } = useGetClientQuery(email, {skip: !email});
    const {
        data: order,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetOrderByIdQuery(id, {
        skip: !id // Skip the query if email is empty
    });

    console.log(order);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isClientError) setErr(getErrorMessage(clientError));
    }, [error, isError, clientError, isClientError]);

    const clearError = () => {
        setErr(null);
    };
    let productList

    let result = <h1>Loading...</h1>
    if (order && isSuccess && isClientSuccess) {
        const createdAt = order.createdAt;
        const parsedDate = parseISO(createdAt);
        const formattedDate = format(parsedDate, 'dd.MM.yyyy')
        const returnDeadline = addDays(new Date(), 14);
        const currentDate = new Date()
        const isEligibleForReturn = isAfter(returnDeadline, currentDate)

        let orderStatus
        isEligibleForReturn
            ? orderStatus = (<div
                className="order__details__item order__status">
                <p className="order__status-current"> STATUS: <span>{ORDER_STATUS_TRANSLATION[order.status].toUpperCase()}</span>
                </p>
                <h3 className="order__status__date-title">Data zakupu:</h3>
                <p className="order__status__date">{formattedDate}</p>
                {order.status === "delivered" && <Link className="link-button" to="/returns">ZWRÓĆ</Link>}
            </div>)
            : orderStatus = (<div
                className="order__details__item order__status">
                <p className="order__status-current"> STATUS: <span>{ORDER_STATUS_TRANSLATION[order.status].toUpperCase()}</span>
                </p>
                <p>Termin zwrotu i wymiany minął. Używaj z przyjemnością!</p>
            </div>)
        const isParcelLocker = order.shipment === "InPostPaczkomat"
        productList = order.products.map(item => <SummaryProduct key={item._id} item={item}/>)

        const giftCardCounter = order.products.reduce((acc, product) => {
            if (product.name === "Karta podarunkowa") {
                return acc + product.quantity
            }
            return acc
        }, 0)
        result = (
            <>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <Link
                    className="close-button"
                    to={'/account'}
                ><Close/></Link>
                {isClientLoading && <h1>Loading...</h1>}
                {isClientSuccess && <div className='client__panel'>
                    <ClientPanelHeader client={client}/>
                    <div className="order__view row">
                        <div className="order__view__column order__details col-18 col-lg-7">
                            <h2 className="order__details-title">NR ZAMÓWIENIA: {order.number}</h2>
                            {orderStatus}
                            <div className="order__details__item">
                                <h3 className="order__details__item-title">PŁATNOŚĆ</h3>
                                <p className="detail">Status: {SINGLE_PAYMENT_STATUS_TRANSLATION[order.payment.status]}</p>
                            </div>
                            <div className="order__details__item">
                                <h3 className="order__details__item-title">WYSYŁKA</h3>
                                <p className="detail">Status: {SINGLE_SHIPMENT_STATUS_TRANSLATION[order.shipmentStatus]}</p>
                                <p className="detail">Rodzaj
                                    wysyłki: {SHIPMENT_OPTION_TRANSLATION[order.shipment]}</p>

                                {isParcelLocker
                                    ? <>
                                        <p className="detail">Paczkomat: {order.selectedPoint.name}</p>
                                        <p className="detail">{order.selectedPoint.address_details.street} {order.selectedPoint.address_details.building_number}</p>
                                        <p className="detail">{order.selectedPoint.address_details.post_code} {order.selectedPoint.address_details.city} </p>
                                    </>
                                    : null
                                }
                            </div>

                            <div className="order__details__item">
                                <h3 className="order__details__item-title">DANE ZAMÓWIENIA</h3>
                                <p className="detail">{order.name} {order.surname}</p>
                                <p className="detail">{order.country?.name}</p>
                                <p className="detail">{order.street} {order.buildingNumber}</p>
                                <p className="detail">{order.postCode} {order.city}</p>
                                <p className="detail">{order.phone}</p>
                                <p className="detail">{order.email}</p>
                            </div>
                            {!order.billingAddress?.theSame && <div className="order__details__item">
                                <h3 className="order__details__item-title">DANE DO FATURY</h3>
                                <p className="detail">{order.billingAddress?.name} {order.billingAddress?.surname}</p>
                                <p className="detail">{order.billingAddress?.country?.name}</p>
                                <p className="detail">{order.billingAddress?.street} {order.billingAddress?.buildingNumber}</p>
                                <p className="detail">{order.billingAddress?.postCode} {order.billingAddress?.city}</p>
                                <p className="detail">{order.billingAddress?.phone}</p>
                                <p className="detail">{order.billingAddress?.email}</p>
                            </div>}
                            <div className="order__details__item summary">
                                <h3
                                    className="order__details__item-title"
                                >PODSUMOWANIE</h3>
                                <div className="summary__item">
                                    <span>Koszt produktów:</span>
                                    <span className="summary__item-price">{order.payment?.products} PLN</span>
                                </div>
                                {!!order.payment?.discountAmount && <div className="summary__item">
                                    <span>{order.payment?.discount?.type === "procent"
                                        ? `${order.payment?.discount?.name} ${order.payment?.discount?.amount * 100}%`
                                        : order.payment?.discount?.name
                                    }</span>
                                    <span
                                        className="summary__item-price">-{order.payment?.discountAmount} PLN</span>
                                </div>}
                                {!!order.payment?.giftCards && <div className="summary__item">
                                    <span>{giftCardCounter > 1 ? "Karty podarunkowe" : "Karta podarunkowa"}</span>
                                    <span className="summary__item-price">{order.payment?.giftCards} PLN</span>
                                </div>}
                                <div className="summary__item">
                                    <span>Wysyłka:</span>
                                    <span className="summary__item-price">{order.payment?.shipment} PLN</span>
                                </div>
                                <div className="summary__item">
                                    <span>Suma:</span>
                                    <span>{order.payment?.total} PLN</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-18 col-lg-11 order__summary__cart">
                            {productList}
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
    return result

};

export default ClientPanelOrderView;