import React, {useEffect, useState} from 'react';
import {noBackgroundScroll} from "../../utils/noBackgroundScroll";

const RemoveButton = ({onRemove, text, disabled}) => {
    const [display, setDisplay] = useState()
    useEffect(() => {
        if (display) {
            noBackgroundScroll("add")
            // Cleanup the effect when the component is unmounted
            return () => {
                noBackgroundScroll("remove");
            };
        }
    }, [display, setDisplay]);

    const onRemoveClicked = () => {
        setDisplay(false)
        onRemove()
    }

    return (
        <div className="remove">
            <button
                className="remove__button-text"
                onClick={() => setDisplay(true)}
                disabled={disabled}
            >USUŃ
            </button>
            {display
                ? <div className='overlay'>
                    <div className="popup__confirmation">
                        <p className="popup__confirmation-text">
                            {text}
                        </p>
                        <div className="popup__confirmation-buttons">
                            <button
                                className="popup__confirmation-button yellow-button"
                                onClick={() => setDisplay(false)}
                            >Anuluj
                            </button>
                            <button
                                className="popup__confirmation-button yellow-button"
                                onClick={onRemoveClicked}
                            > Usuń
                            </button>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
};

export default RemoveButton;