import {Link} from "react-router-dom";
import RemoveButton from "../../../components/Reusable/RemoveButton";
import React, {useEffect, useState} from "react";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {useDeleteNewsletterMutation, useGetNewsletterHistoryQuery} from "../../Newsletter/newsletterApiSlice";
import {format, parseISO} from "date-fns";
import AdminPreviewNewsletter from "./AdminPreviewNewsletter";
import useConfig from "../../../hooks/useConfig";

const AdminNewsletterHistoryRow = ({id, number, setError}) => {
    const [newsletter, setNewsletter] = useState(null)
    const [showPreview, setShowPreview] = useState(false)
    const {CONSTANTS} = useConfig()
    const {data} = useGetNewsletterHistoryQuery("newslettersList", {
        selectFromResult: ({data}) => ({
            data: data?.entities[id]
        }),
    })

    const [deleteNewsletter, {
        isError,
        error
    }] = useDeleteNewsletterMutation();

    useEffect(() => {
        if (!data) return
        const body = data.body
            .replace("cid:logo", `${CONSTANTS.SERVER_ADDRESS}/img/logo192.png`)
            .replace("cid:content", `${CONSTANTS.SERVER_ADDRESS}/img/newsletter/${data.fileName}`)
        setNewsletter({
            ...data,
            body
        })
    }, [data]);


    useEffect(() => {
        if (isError) setError(getErrorMessage(error));
    }, [error, isError]);

    const onDeleteNewsletterClicked = async () => {
        try {
            await deleteNewsletter(id).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    const toggleShowPreview = () => {
        setShowPreview(prev => !prev)
    }


    let result = null
    if (newsletter) {
        const newsletterDate = format(parseISO(newsletter.createdAt), 'dd.MM.yyyy')
        const newsletterTime = format(parseISO(newsletter.createdAt), 'HH:mm:ss')
        result = (
            <div className="admin__panel__section__list admin__newsletter__list">
                {showPreview
                    ? <AdminPreviewNewsletter newsletter={newsletter} toggleShowPreview={toggleShowPreview}/>
                    : null
                }
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {newsletter?.number}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {newsletter?.title}
                </div>

                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    <div>
                        <span>{newsletterDate} r. </span>
                        <span>godz: {newsletterTime}</span>
                    </div>
                </div>
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    <button
                        className="list-item-link"
                        onClick={toggleShowPreview}>PODGlĄD
                    </button>
                    <RemoveButton onRemove={onDeleteNewsletterClicked}
                                  text="Czy na pewno chcesz trwale usunąć ten newsletter z historii?"
                    />
                    <Link
                        className="list-item-link"
                        to={`/admin/newsletter_history/json?id=${id}`}>JSON
                    </Link>
                </div>
            </div>
        );
    }
    return result

};

export default AdminNewsletterHistoryRow;