import React, {useEffect, useState} from 'react';
import ChooseProduct from "../Products/ChooseProduct/ChooseProduct";
import Landing from "./Landing";
import Subscribe from "../Newsletter/Subscribe";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import RodoConsent from "./RodoConsent";


const LandingPage = () => {
    const [err, setErr] = useState(null);
    const [showConsent, setShowConsent] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem("rodoAccepted");
        if (!consentGiven) {
            const timer = setTimeout(() => {
                setShowConsent(true); // Show the consent widget after a delay
            }, 2000); // 3-second delay
            return () => clearTimeout(timer); // Clean up the timer
        }
    }, []);


    const clearError = () => {
        setErr(null);
    };

    return (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {showConsent
                ? <RodoConsent setShowConsent={setShowConsent}/>
                : null}
            <Landing/>
            <ChooseProduct setErr={setErr}/>
            <Subscribe/>
        </>)
};

export default LandingPage;