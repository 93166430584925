import ProductCard from "./ProductCard";
import {Link, useLocation} from "react-router-dom";
import {useGetProductsQuery} from "../producsApiSlice";
import {useEffect} from "react";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const ChooseProduct = ({setErr}) => {
    const isMain = useLocation().pathname === "/"
    const {
        data: products,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProductsQuery('productsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    let result
    if (isLoading) result = <h1>Loading</h1>

    if (isSuccess) {
        const {ids} = products
        const cards = ids.map(el => <ProductCard key={el} productId={el}/>)
        result = (
            <section className="choose" id="choose">
                <div className="col-10 choose__header">
                    <h1 className="choose__header-title">WYBIERZ SWÓJ STYL</h1>
                    <p>WSZYSTKIE KOSZULKI SĄ UNIKATOWE. CECHUJE JE ORYGINALNOŚĆ, WYGODA, DOPASOWANIE I
                        FUNKCJONALNOŚĆ.</p>
                </div>
                <div className="row choose__content">
                    {cards}
                </div>
                {isMain && <Link
                    className='link-button choose__viewAll'
                    to="/shop">Wszystkie produkty</Link>}
            </section>)
        return result
    }
};

export default ChooseProduct;