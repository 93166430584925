import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useDeleteClientMutation, useGetClientsQuery} from "../../Clients/clientApiSlice";
import RemoveButton from "../../../components/Reusable/RemoveButton";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminClientRow = ({id, number, setError}) => {
    const {client} = useGetClientsQuery("clientsList", {
        selectFromResult: ({data}) => ({
            client: data?.entities[id]
        }),
    })

    const [deleteClient, {
        isError,
        error
    }] = useDeleteClientMutation();


    useEffect(() => {
        if (isError) setError(getErrorMessage(error));
    }, [error, isError]);

    const onRemoveClientClicked = async () => {
        try {
            await deleteClient(id).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    let result = null
    if (client) {

        result = (
            <div className="admin__panel__section__list admin__clients__list ">
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    <RemoveButton onRemove={onRemoveClientClicked}
                                  text="Czy na pewno chcesz trwale usunąć konto tego klienta?"/>
                </div>

                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {client?.name}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {client?.surname}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {client?.email}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {client?.active
                        ? <span className="acive">Aktywne</span>
                        : <span className="inacive">Nieaktywne</span>}
                </div>

                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    <Link
                        className="list-item-link"
                        to={`/admin/clients/client?id=${id}`}>EDYTUJ</Link>
                    <Link
                        className="list-item-link"
                        to={`/admin/clients/json?id=${id}`}>JSON</Link>
                </div>
            </div>
        );
    }
    return result

};

export default AdminClientRow;