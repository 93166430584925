import React, {useEffect, useState} from 'react';
import ChooseProduct from "./ChooseProduct";
import NewsLetter from "../../Newsletter/NewsLetter";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import RodoConsent from "../../LandingPage/RodoConsent";

const Products = () => {
    const [err, setErr] = useState(null);
    const [showConsent, setShowConsent] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem("rodoAccepted");
        if (!consentGiven) {
            const timer = setTimeout(() => {
                setShowConsent(true); // Show the consent widget after a delay
            }, 2000); // 3-second delay
            return () => clearTimeout(timer); // Clean up the timer
        }
    }, []);

    const clearError = () => {
        setErr(null);
    };

    const result = (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {showConsent
                ? <RodoConsent setShowConsent={setShowConsent}/>
                : null}
            <ChooseProduct setErr={setErr}/>
            <NewsLetter/>
        </>
    )
    return result
};

export default Products;