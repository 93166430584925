import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../../app/api/apiSlice";
import {logOut, logoutAndReset} from "../../Auth/authSlice";

const subscribersAdapter = createEntityAdapter({});

const initialState = subscribersAdapter.getInitialState();

export const subscriptionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubscriptions: builder.query({
            query: () => ({
                url: '/api/admin/subscriptions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedSubscriptions = responseData.map(subscriber => {
                    subscriber.id = subscriber._id;
                    return subscriber;
                });
                return subscribersAdapter.setAll(initialState, loadedSubscriptions);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Subscription', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Subscription', id}))
                    ];
                } else return [{type: 'Subscription', id: 'LIST'}];
            }
        }),

        addNewSubscription: builder.mutation({
            query: (initialSubscriptionData) => ({
                url: '/api/subscriptions/add',
                method: 'POST',
                body: {
                    ...initialSubscriptionData
                },
                validateStatus: (response, result) => {
                    return response.status === 201 && !result.isError;
                }
            }),
        }),

        getUnsubscribe: builder.query({
            query: (token) => ({
                url: `/api/subscriptions/remove/${token}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
        }),

        updateSubscription: builder.mutation({
            query: (initialSubscriptionData) => ({
                url: '/api/admin/subscriptions',
                method: 'PATCH',
                body: {
                    ...initialSubscriptionData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
                invalidatesTags: (result, error, arg) => [
                    {type: 'Subscription', id: arg.id}
                ],
            }),
        }),
        deleteSubscription: builder.mutation({
            query: (id) => ({
                url: '/api/admin/subscriptions',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Subscription', id: arg.id}
            ],
        })
    })
});

export const {
    useGetSubscriptionsQuery,
    useGetUnsubscribeQuery,
    useAddNewSubscriptionMutation,
    useDeleteSubscriptionMutation,
    useUpdateSubscriptionMutation,
} = subscriptionApiSlice;

// returns the query object
export const selectSubscriptionsResult = subscriptionApiSlice.endpoints.getSubscriptions.select();

// creates memoized selector
const selectSubscriptionsData = createSelector(
    selectSubscriptionsResult,
    subscribersResult => subscribersResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllSubscriptions,
    selectById: selectSubscriptionById,
    selectIds: selectSubscriptionIds
    // pass in a selector that returns the subscribers slice of state
} = subscribersAdapter.getSelectors(state => selectSubscriptionsData(state) ?? initialState);
