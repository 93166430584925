import React, {useEffect, useState} from 'react';
import useQuery from "../../../hooks/useQuery";
import {Link, useNavigate} from "react-router-dom";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {useGetOrdersQuery, useUpdateOrderMutation} from "../../Orders/orderApiSlice";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import PanelSectionSelect from "../AdminPanelSection/PanelSectionSelect";
import PanelSectionTextarea from "../AdminPanelSection/PanelSectionTextarea";
import PanelSectionCheckBox from "../AdminPanelSection/PanelSectionCheckBox";
import validateOrderData from "../../../utils/validateOrderData";
import useGetLocations from "../../../hooks/useGetLocations";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminEditOrder = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const orderId = useQuery('id');
    const [order, setOrder] = useState(null)
    const [check, setCheck] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const CONFIG = useConfig()
    const locations = useGetLocations()
    locations.pop()

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetOrdersQuery("ordersList", {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[orderId],
            ...other
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [updateOrder, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateOrderMutation()

    useEffect(() => {
        if (isUpdateSuccess) navigate("/admin/orders")
    }, [updateResult, isUpdateSuccess]);


    useEffect(() => {
        if (order) {
            setFormError(null);
            setIsValid(validateOrderData(order, CONFIG));
        }
    }, [order]);

    useEffect(() => {
    }, [isValid]);


    useEffect(() => {
        if (isSuccess && data) {
            const status = CONFIG.CONSTANTS.ORDER_STATUS_LIST.find(el => el.name === data.status) || ""
            const orderData = {...data, status}
            delete orderData.products
            setOrder(orderData)
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isUpdateError) setErr(getErrorMessage(updateError));
    }, [error, isError, updateError, isUpdateError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        if (e.target.type === "number") value = Number(value)
        setOrder(prev => ({...prev, [id]: value}))
    }
    const setBillingData = (e) => {
        let {value, checked, dataset: {id}} = e.target;
        if (e.target.type === "number") value = Number(value)
        if (id === "theSame" && checked) {
            setOrder(prev => ({
                ...prev,
                billingAddress: {
                    ...prev.billingAddress,
                    theSame: checked,
                    email: "",
                    country: {id: 21, name: "Poland", code: "PL", language: "pl"},
                    name: "",
                    surname: "",
                    street: "",
                    postCode: "",
                    city: "",
                    phone: "",
                    notes: ""
                }
            }))
            return
        }
        setOrder(prev => ({
            ...prev,
            billingAddress: {
                ...prev.billingAddress,
                [id]: id === "theSame" ? checked : value
            }
        }))
    }

    const onSaveOrderClicked = async (e) => {
        e.preventDefault()
        // Check if form is valid
        setCheck(true)
        if (!isValid.all) {
            setFormError('Dane zamówienia nie są kompletne.');
            console.log('Order data incomplete');
            return
        }

        try {
            await updateOrder({
                ...order,
                status: order.status.name
            }).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista zamówień"}
    </Link>)
    if (isLoading) return <h1>Loading...</h1>
    if (!order) return null;
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Zamówienie nr {order.number}</h1>
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <button
                    className=" admin__panel__section__buttons-button yellow-button"
                    onClick={onSaveOrderClicked}
                >{isUpdateLoading ? "Loading..." : "Zapisz"}
                </button>
            </div>
            <form className="admin__panel__section__from edit__order">
                <PanelSectionSelect
                    isValid="true"
                    check={check}
                    title="STATUS"
                    placeholder="Wybierz status"
                    attribute="status"
                    selected={order.status}
                    setState={setOrder}
                    list={CONFIG.CONSTANTS.ORDER_STATUS_LIST}
                />
                <PanelSectionInput
                    isValid={isValid?.isNameValid}
                    check={check}
                    value={order.name}
                    type="text"
                    setInput={setInput}
                    title="IMIĘ"
                    name="name"
                    placeholder="Imię"
                />
                <PanelSectionInput
                    isValid={isValid?.isSurnameValid}
                    check={check}
                    value={order.surname}
                    type="text"
                    setInput={setInput}
                    title="NAZWISKO"
                    name="surname"
                    placeholder="Nazwisko"
                />
                <PanelSectionInput
                    isValid={isValid?.isEmailValid}
                    check={check}
                    value={order.email}
                    type="email"
                    setInput={setInput}
                    title="E-MAIL"
                    name="email"
                    placeholder="E-mail"
                />

                <PanelSectionSelect
                    isValid={isValid?.isCountryValid}
                    check={check}
                    title="KRAJ"
                    placeholder="Wybierz kraj"
                    attribute="country"
                    selected={order.country}
                    setState={setOrder}
                    list={CONFIG.CONSTANTS.COUNTRIES}
                />

                <PanelSectionInput
                    isValid={isValid?.isStreetValid}
                    check={check}
                    value={order.street}
                    type="text"
                    setInput={setInput}
                    title="ULICA"
                    name="street"
                    placeholder="Ulica"
                />
                <PanelSectionInput
                    isValid={isValid?.buildingNumber}
                    check={check}
                    value={order.buildingNumber}
                    type="text"
                    setInput={setInput}
                    title="NR DOMU"
                    name="buildingNumber"
                    placeholder="Nr domu"
                />
                <PanelSectionInput
                    isValid={isValid?.isPostCodeValid}
                    check={check}
                    value={order.postCode}
                    type="text"
                    setInput={setInput}
                    title="KOD POCZTOWY"
                    name="postCode"
                    placeholder="Kod pocztowy"
                />
                <PanelSectionInput
                    isValid={isValid?.isCityValid}
                    check={check}
                    value={order.city}
                    type="text"
                    setInput={setInput}
                    title="MIASTO"
                    name="city"
                    placeholder="Miasto"
                />

                <PanelSectionTextarea
                    isValid="true"
                    check={check}
                    value={order.notes}
                    setInput={setInput}
                    title="DODATKOWE INFORMACJE"
                    name="notes"
                    placeholder="Edytuj"
                />

                <PanelSectionCheckBox
                    checked={order.billingAddress.theSame}
                    title="DANE DO FAKTURY"
                    name="theSame"
                    label="Takie same jak dane dostawy"
                    setChecked={setBillingData}
                />

                {!order.billingAddress.theSame && (
                    <>
                        <PanelSectionSelect
                            isValid={isValid?.billingAddress?.isCountryValid}
                            check={check}
                            title="KRAJ"
                            placeholder="Wybierz kraj"
                            attribute="country"
                            attributeId="billingCountry"
                            selected={order.billingAddress.country}
                            setState={setOrder}
                            list={CONFIG.CONSTANTS.COUNTRIES}
                        />

                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isNameValid}
                            check={check}
                            value={order.billingAddress.name}
                            type="text"
                            setInput={setBillingData}
                            title="IMIĘ"
                            name="name"
                            placeholder="Imię"
                        />
                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isSurnameValid}
                            check={check}
                            value={order.billingAddress.surname}
                            type="text"
                            setInput={setBillingData}
                            title="NAZWISKO"
                            name="surname"
                            placeholder="Nazwisko"
                        />
                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isStreetValid}
                            check={check}
                            value={order.billingAddress.street}
                            type="text"
                            setInput={setBillingData}
                            title="ULICA"
                            name="street"
                            placeholder="Ulica"
                        />
                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isBuildingNumberValid}
                            check={check}
                            value={order.billingAddress.buildingNumber}
                            type="text"
                            setInput={setBillingData}
                            title="NR DOMU"
                            name="buildingNumber"
                            placeholder="Nr domu"
                        />
                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isPostCodeValid}
                            check={check}
                            value={order.billingAddress.postCode}
                            type="text"
                            setInput={setBillingData}
                            title="KOD POCZTOWY"
                            name="postCode"
                            placeholder="Kod pocztowy"
                        />
                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isCityValid}
                            check={check}
                            value={order.billingAddress.city}
                            type="text"
                            setInput={setBillingData}
                            title="MIASTO"
                            name="city"
                            placeholder="Miasto"
                        />
                        <PanelSectionTextarea
                            isValid="true"
                            check={check}
                            value={order.billingAddress.notes}
                            setInput={setBillingData}
                            title="DODATKOWE INFORMACJE"
                            name="notes"
                            placeholder="Edytuj"
                        />
                        <PanelSectionInput
                            isValid={isValid?.billingAddress.isEmailValid}
                            check={check}
                            value={order.billingAddress.phone}
                            setInput={setBillingData}
                            title="TELEFON"
                            name="phone"
                            placeholder="Telefon"
                        />
                    </>
                )}
            </form>
        </section>
    )
};

export default AdminEditOrder;