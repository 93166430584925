import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";
import {ReactComponent as IgBlack} from "../../images/igBlack.svg";
import {ReactComponent as FbBlack} from "../../images/fbBlack.svg";
import {ReactComponent as PhoneBlack} from "../../images/phoneBlack.svg";
import {ReactComponent as MailBlack} from "../../images/emailBlack.svg";
import {Link} from "react-router-dom";

const Contact = () => {
    return (
        <>
            <InnerSection>
                <div className='contact__content'>
                    <div className='contact__content__column contact__content__column-left '>
                        <h2 className="contact__content__column-title"> Kontakt</h2>
                        <Link
                            className='contact__content__column-link'
                            to="https://www.instagram.com/bebrave.wear/"
                        >
                            <IgBlack/>
                            instagram.com/bebrave.wear
                        </Link>
                        <Link className='contact__content__column-link'
                              to="https://www.facebook.com/bebreve.wear"
                        >
                            <FbBlack/>
                            facebook.com/bebrave.wear
                        </Link>
                        <p className='contact__content__column-link'>
                            <PhoneBlack/>
                            (+48) 510 669 711
                        </p>
                        <Link
                            className='contact__content__column-link'
                            to="mailto:kontakt@beberave.com.pl">
                            <MailBlack/>
                            kontakt@bebrave.com.pl
                        </Link>
                    </div>
                    <div className='contact__content__column contact__content__column-right '>
                        <h2 className="contact__content__column-title">
                            {/*<LocationBlack/>*/}
                            Znajdziesz nas stacjonarnie:
                        </h2>
                        <h3 className="contact__content__column-city">Warszawa</h3>
                        <ul>
                            <li>
                                <Link
                                    className='contact__content__column-link'
                                    to='https://arenamakak.pl/'>Arena Wspinaczkowa Makak</Link>
                                <span>Palisadowa 20/22, 01-940 Warszawa</span>
                            </li>
                            {/*    <li>*/}
                            {/*        WEST Bouldering <span>Krakowiaków 80/98, 02-255 Warszawa</span>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        WspinSklep*/}
                            {/*        <span>Krakowska 1, 01-000 Kraków</span>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            {/*<h3 className="contact__content__column-city">Kraków</h3>*/}
                            {/*<ul>*/}
                            {/*    <li>*/}
                            {/*        WspinSklep*/}
                            {/*        <span>Krakowska 1, 01-000 Kraków</span>*/}
                            {/*    </li>*/}
                        </ul>
                    </div>
                </div>
            </InnerSection>
            <NewsLetter/>
        </>
    )
};

export default Contact;