import React, {useEffect} from 'react';
import {useDeleteSubscriptionMutation, useGetSubscriptionsQuery} from "./subscriptionsApiSlice";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import RemoveButton from "../../../components/Reusable/RemoveButton";
import {Link} from "react-router-dom";

const AdminSubscriptionRow = ({id, setError, number}) => {
    const {subscription} = useGetSubscriptionsQuery("subscriptionsList", {
        selectFromResult: ({data}) => ({
            subscription: data?.entities[id]
        }),
    })

    const [deleteSubscription, {
        isError,
        error
    }] = useDeleteSubscriptionMutation();


    useEffect(() => {
        if (isError) setError(getErrorMessage(error));
    }, [error, isError]);

    const onRemoveClientClicked = async () => {
        try {
            await deleteSubscription(id).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    let result = null
    if (subscription) {
        result = (
            <div className="admin__panel__section__list admin__subscriptions__list ">
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {number}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {subscription?.email}
                </div>

                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {subscription?.client.status}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {subscription?.client.clientId}
                </div>

                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    <Link
                        className="list-item-link"
                        to={`/admin/subscriptions/subscription?id=${id}`}>EDYTUJ
                    </Link>
                    <RemoveButton onRemove={onRemoveClientClicked}
                                  text="Czy na pewno chcesz trwale usunąć konto tę subskrybcję?"
                    />
                    <Link
                        className="list-item-link"
                        to={`/admin/subscriptions/json?id=${id}`}>JSON
                    </Link>
                </div>
            </div>
        );
    }
    return result

};

export default AdminSubscriptionRow;