import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";


const paymentsAdapter = createEntityAdapter({});

const initialState = paymentsAdapter.getInitialState();

export const paymentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        testAccess: builder.query({
            query: (code) => ({
                url: `/api/admin/payments/test`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
        }),

        getPayments: builder.query({
            query: () => ({
                url: '/api/admin/payments',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedPayments = responseData.map(payment => {
                    payment.id = payment._id;
                    return payment;
                }).sort((prev, curr) => new Date(curr.createdAt) - new Date(prev.createdAt)); // Compare as dates


                return paymentsAdapter.setAll(initialState, loadedPayments);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Payment', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Payment', id}))
                    ];
                } else return [{type: 'Payment', id: 'LIST'}];
            }
        }),

        updatePayment: builder.mutation({
            query: (initialPaymentData) => ({
                url: '/api/admin/payments',
                method: 'PATCH',
                body: {
                    ...initialPaymentData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Payment', id: arg.id},
            ],
        }),
        deletePayment: builder.mutation({
            query: (id) => ({
                url: '/api/admin/payments',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Payment', id: arg.id}
            ],
        })
    })
});

export const {
    useTestAccessQuery,
    useGetPaymentsQuery,
    useUpdatePaymentMutation,
} = paymentApiSlice;

// returns the query object
export const selectPaymentsResult = paymentApiSlice.endpoints.getPayments.select();
// returns the query object

// creates memoized selector
const selectPaymentsData = createSelector(
    selectPaymentsResult,
    paymentsResult => paymentsResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllPayments,
    selectById: selectPaymentById,
    selectIds: selectPaymentIds
} = paymentsAdapter.getSelectors(state => selectPaymentsData(state) ?? initialState);
