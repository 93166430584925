import {useEffect, useState, useRef} from 'react';
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link, useNavigate} from "react-router-dom";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {convertFilesToBase64} from "../../../utils/convertFilesToBase64";
import AdminSendNewsLetterForm from "./AdminSendNewsLetterForm";
import {useGetNewsletterTemplateQuery, useSendNewsletterMutation} from "../../Newsletter/newsletterApiSlice";

const AdminSendNewsletter = () => {
    const {CONSTANTS} = useConfig();
    const navigate = useNavigate();
    const [err, setErr] = useState(null);
    const [formError, setFormError] = useState(null)
    const [state, setState] = useState({
        title: "",
        template: "",
        selectedFile: null,
        previewURL: null
    });
    const contentRef = useRef(null);

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNewsletterTemplateQuery();

    const [sendNewsletter, {
        isLoading: isSendNewsletterLoading,
        isSuccess: isSendNewsletterSuccess,
        isError: isSendNewsletterError,
        error: sendNewsletterError
    }] = useSendNewsletterMutation();

    useEffect(() => {
        if (data) {
            const previewURL = state.previewURL ? `src="${state.previewURL}"` : `src=${null}`;
            const template = data.content
                .replace('src="cid:logo"', `src="${CONSTANTS.SERVER_ADDRESS}/img/logo192.png"`)
                .replace('src="cid:content"', previewURL)
                .replace('{title}', state.title);

            setState((prev) => ({...prev, template}));
        }
    }, [data, CONSTANTS.SERVER_ADDRESS, state.title, state.previewURL]);


    useEffect(() => {
        if (isSendNewsletterSuccess) {
            navigate('/admin');
        }
    }, [isSendNewsletterSuccess, navigate]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isSendNewsletterError) setErr(getErrorMessage(sendNewsletterError));
    }, [isError, error, isSendNewsletterError, sendNewsletterError]);

    const clearError = () => setErr(null);

    const onSendNewsletter = async () => {

        if (!state.title) {
            setFormError("Wpisz tytuł!")
            return
        }
        if (!state.previewURL || !state.selectedFile) {
            setFormError("Wybierz grafikę!")
            return
        }

        const previewURL = state.previewURL ? `src="${state.previewURL}"` : `src="${CONSTANTS.SERVER_ADDRESS}/img/logo192.png"`;
        const base64File = await convertFilesToBase64([state.selectedFile])
        const content = contentRef.current.innerHTML
            .replace(`src="${CONSTANTS.SERVER_ADDRESS}/img/logo192.png"`, 'src="cid:logo"')
            .replace(previewURL, 'src="cid:content"');
        const newsletterObject = {
            content,
            title: state.title,
            selectedFile: base64File
        }
        try {
            await sendNewsletter(newsletterObject).unwrap();
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    };

    if (isLoading) return <h1>Wczytuję...</h1>;

    return (
        <section className="admin__panel__section col-18 col-lg-16 edit__newsletter">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>WYŚLIJ NEWSLETTER</h1>
            <div className="admin__panel__section__buttons">
                <Link
                    className="admin__panel__section__buttons-button go-back blue-button"
                    to="/admin">
                    Wróć
                </Link>

                <AdminSendNewsLetterForm
                    setState={setState}
                    state={state}
                    setFormError={setFormError}
                />

                <button
                    className="admin__panel__section__buttons-button yellow-button"
                    onClick={onSendNewsletter}
                    disabled={isSendNewsletterLoading}
                >
                    {isSendNewsletterLoading ? 'Wysyłanie...' : 'Wyślij'}
                </button>
            </div>
            <span className='error-message'>{formError}</span>
            <div className="edit__newsletter__content">
                <div className="col-18 col-md-12 edit__newsletter__content__preview">
                    <div id="content" ref={contentRef}
                         dangerouslySetInnerHTML={{__html: state.template}}
                    />
                </div>
            </div>
        </section>
    );
};

export default AdminSendNewsletter;
