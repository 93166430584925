import React, {useEffect, useRef, useState} from "react";
import {useLoginMutation, useVerifyMutation} from "./authApiSlice";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux'
import usePersist from "../../hooks/usePersist";
import InnerSection from "../../components/Reusable/InnerSection";
import CheckBox from "../../components/Reusable/CheckBox";
import {ReactComponent as Close} from "../../images/close.svg";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import useAuth from "../../hooks/useAuth";
import PasswordInput from "../../components/Reusable/PasswordInput";
import {getErrorMessage} from "../../utils/getErrorMessage";
import TwoFactorVerify from "./TwoFactorVerify";
import TwoFactorEnable from "./TwoFactorEnable";
import {processAccessToken} from "../../utils/processAccessToken";
import SubmitButton from "../../components/Reusable/SubmitButton";

const Login = () => {
    const emailRef = useRef()
    const {isLoggedIn} = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [err, setErr] = useState(null);
    const [formError, setFormError] = useState(null)
    const [persist, setPersist] = usePersist()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [twoFactor, setTwoFactor] = useState({
        code: "",
        qrCode: null,
        showForm: false
    })
    const code = useRef("")

    const [login, {
        isLoading,
        isError,
        error
    }] = useLoginMutation()

    const [verify, {
        isError: isVerifyError,
        error: verifyError
    }] = useVerifyMutation()

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/")
        } else {
            emailRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setFormError('')
    }, [email, password]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isVerifyError) setErr(getErrorMessage(verifyError));
    }, [error, isError, verifyError, isVerifyError]);

    const clearError = () => setErr(null);
    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleTogglePersist = () => setPersist(prev => !prev)
    const onCreateAccountClicked = () => navigate('/create_account')

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!email.length) {
                setFormError("Podaj e-mail.")
                return
            }
            if (!password.length) {
                setFormError("Podaj hasło.")
                return
            }
            const result = await login({email, password, persist}).unwrap()
            processAccessToken(result, navigate, dispatch, setTwoFactor, setEmail, setPassword)

        } catch (err) {
            console.error(getErrorMessage(err))
        }
    }

    const handleVerify = async () => {
        try {
            const result = await verify({token: code.current.value, email, password, persist}).unwrap()
            processAccessToken(result, navigate, dispatch, setTwoFactor, setEmail, setPassword)
        } catch (err) {
            console.error(getErrorMessage(err))
        }
    }

    const content = (
        <>
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className='login__content'>
                    {twoFactor.showForm
                        ? <TwoFactorVerify
                            code={code}
                            setTwoFactor={setTwoFactor}
                            handleVerify={handleVerify}/>
                        : null}
                    {twoFactor.qrCode
                        ? <TwoFactorEnable
                            qrCode={twoFactor.qrCode}
                            close={setTwoFactor}/>
                        : null}
                    <Link className="close-button" to="/"><Close/></Link>
                    <form
                        onSubmit={handleSubmit}
                        className='login__content__column login__content__column-left login__left'>
                        <h2 className="login__content__column-title"> Logowanie</h2>
                        <input
                            className='custom-input'
                            type='email'
                            name='email'
                            placeholder='E-mail'
                            onChange={handleUserInput}
                            ref={emailRef}
                            autoComplete="on"
                        />

                        <PasswordInput handlePwdInput={handlePwdInput}/>

                        <CheckBox
                            name={"persist"}
                            label={"zapamiętaj mnie"}
                            checked={persist}
                            setChecked={handleTogglePersist}
                        />
                        <SubmitButton
                            className="link-button"
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                            errorMessage={formError}
                            text="ZALOGUJ SIĘ"
                            isLoadingText="LOGUJĘ..."
                        />
                        <Link className='reset-password'
                              to="/reset_password">Nie pamiętam hasła</Link>

                    </form>
                    <div className='login__content__column login__content__column-right'>
                        <h2 className="login__content__column-title"> Rejestracja</h2>
                        <p className='login__content__column-text'> NIE MASZ KONTA?</p>
                        <p className='login__content__column-text'> ZAREJESTRUJ SIĘ TERAZ:</p>
                        <ul className='login__content__column-list'>
                            <li className='login__content__column__list-item'>Bądź na bieżąco z nowościami</li>
                            <li className='login__content__column__list-item'>Pełen dostęp do historii zamówień</li>
                            <li className='login__content__column__list-item'>Śledzenie statusu zamówienia</li>
                            <button onClick={onCreateAccountClicked} className='link-button'>STWÓRZ KONTO</button>
                        </ul>
                    </div>
                </div>
            </InnerSection>
        </>
    );

    return isLoggedIn ? null : content
}
export default Login
