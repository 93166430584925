import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useGetOrdersQuery} from "../Orders/orderApiSlice";
import {useGetAllProductsQuery} from "../Products/producsApiSlice";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import {getErrorMessage} from "../../utils/getErrorMessage";

const AdminPanel = () => {
    const [err, setErr] = useState();
    const [pendingOrders, setPendingOrders] = useState(null)
    const {
        data: orders,
        isError,
        error
    } = useGetOrdersQuery('ordersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: giftCard,
        isError: isGiftCardError,
        error: giftCrdError
    } = useGetAllProductsQuery('adminProductsList', {
        selectFromResult: ({data, ...other}) => {
            return {
                data: data?.entities ? Object.values(data?.entities).find(el => el.name === "Karta podarunkowa") : undefined,
                ...other
            }
        },
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (orders) {
            const pendingOrdersCount = Object.values(orders.entities).filter(el => el.status === "processed").length;
            if (pendingOrdersCount) setPendingOrders(pendingOrdersCount)
        }
    }, [orders]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isGiftCardError) setErr(getErrorMessage(giftCrdError));
    }, [error, isError, giftCrdError, isGiftCardError]);

    const clearError = () => {
        setErr(null);
    };

    return (
        <div className="admin__panel__tiles col-12 col-lg-16 row">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to="orders">
                ZAMÓWIENIA
                {pendingOrders && <span className="notification">{pendingOrders}</span>}
            </Link>
            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to="payments">
                PŁATNOŚCI
            </Link>
            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to="shipments">
                PRZESYŁKI
            </Link>
            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to="dispatch_orders">
                ZLECENIA ODBIORU
            </Link>
            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to="products">
                PRODUKTY
            </Link>

            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to="clients">
                KLIENCI
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to={giftCard ? 'edit_gift_card' : 'add_gift_card'}>
                KARTA PODARUNKOWA
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="discounts">
                KODY RABATOWE
            </Link>

            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to='edit_rules'>
                REGULAMIN
            </Link>
            <Link
                className="admin__panel__tile col-18 col-lg-3"
                to='edit_about'>
                O NAS
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="edit_shipment_and_costs">
                REGULAMIN DOSTAWY
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3" to="edit_payment">
                REGULAMIN PŁATNOŚCI
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="edit_gift_card_rules">
                REGULAMIN KARTY PREZENTOWEJ
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="edit_privacy_policy">
                POLITYKA PRYWATNOŚCI
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="edit_returns">
                REGULAMIN ZWROTÓW
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="config">
                KONFIGURACJA
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="subscriptions">
                SUBSKRYBCJE
            </Link>

            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="newsletter">
                WYŚLIJ NEWSLETTER
            </Link>
            <Link className="admin__panel__tile col-18 col-lg-3"
                  to="newsletter_history">
                NEWSLETTER HISTORIA
            </Link>
        </div>
    );
};

export default AdminPanel;