import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import useSid from "../../../hooks/useSid";
import useConfig from "../../../hooks/useConfig";
import {noBackgroundScroll} from "../../../utils/noBackgroundScroll";

const SummaryItems = ({newOrder, onMakePaymentClicked, isPaymentLoading}) => {
    // Get the showOrderData state from the Redux store
    const sid = useSid()
    const [showOrderData, setShowOrderData] = useState(false)
    const [data, setData] = useState({email: "", orderNumber: ""});
    const [formError, setFormError] = useState()
    const {CONSTANTS: {PAYMENT_STATUS_TRANSLATION}} = useConfig()
    const email = useRef()

    useEffect(() => {
        email.current.focus();
    }, []);

    useEffect(() => {
        if (sid) {
            setShowOrderData(newOrder.sid === sid);
        }
    }, [sid]);

    const setInput = (e) => {
        setFormError(null)
        setData(prev => (
            {...prev, [e.target.name]: e.target.value}))
    }

    const onShowDataClicked = (e) => {
        e.preventDefault()
        const {email, number} = newOrder;
        if (email === data.email && number === Number(data.orderNumber)) {
            setShowOrderData(true);
        } else {
            setFormError("Nieprawidłowe dane")
        }
    };

    const isPaymentProcessed = newOrder?.payment?.status !== "pending"
        ? (
            <>
                <h3 className="order__summary__item-title">Status płatności</h3>
                <p className="order__summary__item-text">{PAYMENT_STATUS_TRANSLATION[newOrder.payment.status]}</p>
            </>
        ) : (
            <>
                <h3 className="order__summary__item-title">Twoje zamówienie nie jest opłacone</h3>
                <p className="order__summary__item-text">Opłać swoje zamówienie</p>
            </>
        );

    // Conditionally render order data if showOrderData is true
    const ifShowOrderData = showOrderData
        ? (
            <>
                <h2 className="order__summary-title">Zamówienie nr: {newOrder?.number}</h2>
                <p className="order__summary-text">Dziekujemy {newOrder?.name}!</p>
            </>
        ) : (
            <div className="order__summary__item">
                <h3 className="order__summary__item-title">Podaj dane zamówienia aby wyświetlić szczegóły</h3>
                <p className="order__summary__item-text">Numer zamówienia znajdziesz w potwierdzeniu wysłanym
                    e-mailem</p>
                <form onSubmit={onShowDataClicked}>
                    <input
                        ref={email}
                        className='order__summary__item-input'
                        type='email'
                        name='email'
                        placeholder="E-mail"
                        value={data.email}
                        onChange={setInput}
                    />
                    <input
                        className='order__summary__item-input'
                        type="text"
                        name="orderNumber"
                        placeholder="Numer zamówienia"
                        value={data.orderNumber}
                        onChange={setInput}
                    />
                    <span className="error-message">{formError}</span>
                    <button
                        className='link-button'
                        type="submit">
                        WYŚWIETL SZCZEGÓŁY
                    </button>
                </form>
            </div>
        );

    // Render the summary items component
    return (
        <div className="col-18 col-lg-8 order__summary">
            {ifShowOrderData}
            {newOrder?.payment?.status === "pending"
                ? <div className="order__summary__item">
                    <h3 className="order__summary__item-title">Płatność</h3>
                    <p className="order__summary__item-text">Zapłać za pomocą: {newOrder?.payment.method}</p>
                    <button
                        className='link-button payman'
                        onClick={onMakePaymentClicked}
                        disabled={isPaymentLoading}
                    >
                        {isPaymentLoading ? 'REALIZUJĘ PŁATNOŚĆ' : 'ZAPŁAĆ'}
                    </button>
                </div>
                : null}

            <div className="order__summary__item completed">
                {isPaymentProcessed}
            </div>

            {showOrderData && <div className="order__summary__item">
                <h3 className="order__summary__item-title">Szczegóły zamówienia</h3>
                <div className='row'>
                    <div className='col-8'>
                        <h3 className='order__summary__item-text first'>Dane kontaktowe</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.email}
                        </p>
                        <h3 className='order__summary__item-text'>Adres wysyłki</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.name} {newOrder?.surname}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.street} {newOrder?.buildingNumber}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.postCode} {newOrder?.city}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.country?.name}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.phone}
                        </p>
                        <h3 className='order__summary__item-text'>Metoda wysyłki</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.shipment}
                        </p>
                    </div>
                    <div className='col-8'>
                        <h3 className='order__summary__item-text first'>Metoda płatności</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.payment.method} <span> - {newOrder?.payment.total} PLN</span>
                        </p>
                        <h3 className='order__summary__item-text'>Adres rozliczeniowy</h3>
                        {newOrder?.billingAddress?.theSame
                            ? <p className='order__summary__item-detail'>
                                Taki sam jak adres wysyłki
                            </p>
                            : <>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.name} {newOrder?.billingAddress.surname}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.street} {newOrder?.billingAddress.buildingNumber}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.postCode} {newOrder?.billingAddress.city}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.country?.name}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.phone}
                                </p>
                            </>}
                    </div>
                </div>
            </div>}
            {/*<div className="order__summary__item">*/}
            <Link to="/" className="link-button continue">KONTYNUUJ ZAKUPY</Link>
            {/*</div>*/}
        </div>
    );
};

export default SummaryItems;
