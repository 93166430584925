import {ReactComponent as Logo} from "../../images/BeBrave_logo.svg";
import PasswordInput from "../../components/Reusable/PasswordInput";
import "./_loading.scss"
import {useEffect, useState} from "react";
import {useLoginMutation} from "../Auth/authApiSlice";
import {setCredentials} from "../Auth/authSlice";
import {getErrorMessage} from "../../utils/getErrorMessage";
import {Outlet, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import useAuth from "../../hooks/useAuth";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import usePersist from "../../hooks/usePersist";


const Loading2 = () => {
    const [password, setPassword] = useState()
    const [err, setErr] = useState(null);
    const [formError, setFormError] = useState(null)
    const [login, {isLoading, isError, error}] = useLoginMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {isLoggedIn} = useAuth()
    const [_, setPersist] = usePersist()


    useEffect(() => {
        setFormError('')
    }, [password]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!password.length) {
                setFormError("Podaj hasło.")
                return
            }
            const {accessToken} = await login({email: process.env.REACT_APP_EMAIL, password, persist: true}).unwrap()
            dispatch(setCredentials({accessToken}))
            localStorage.setItem("persist", JSON.stringify(true))
            localStorage.removeItem("orderFormData")
            localStorage.removeItem("shoppingCart")

            navigate("/admin")
        } catch (err) {
            console.error(getErrorMessage(err))
        }
    }

    if (isLoggedIn) return <Outlet/>
    const handlePwdInput = (e) => setPassword(e.target.value)
    return (
        <div className="loading">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1 className="loading__title"><Logo/></h1>
            <PasswordInput handlePwdInput={handlePwdInput}/>
            <span className='error-message'>{formError}</span>
            <button
                type="button"
                onClick={handleSubmit}
                className='link-button'
                disabled={isLoading}>
                {isLoading ? "Loading..." : "ZALOGUJ SIĘ"}
            </button>
        </div>
    );
};

export default Loading2;