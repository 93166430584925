import {jwtDecode} from "jwt-decode";
import {setCredentials} from "../features/Auth/authSlice";

export const processAccessToken = (result, navigate, dispatch, setTwoFactor, setEmail, setPassword) => {
    if (result.qrCodeData) {
        setTwoFactor(prev => ({
            ...prev,
            qrCode: result
        }))
    } else if (result.twoFactor) {
        setTwoFactor(prev => ({
            ...prev,
            showForm: true
        }))
        return
    }
    const {accessToken} = result

    const decoded = jwtDecode(accessToken)
    const {isAdmin} = decoded.UserInfo
    dispatch(setCredentials({accessToken}))
    setEmail("")
    setPassword("")

    localStorage.removeItem("orderFormData")
    localStorage.removeItem("shoppingCart")

    if (isAdmin) {
        navigate("/admin")
        return
    }
    navigate("/account")
}