import React, {useEffect, useState, useCallback} from "react";
import CartProduct from './CartProduct';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as Close} from '../../images/close.svg';
import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";
import {useSelector} from "react-redux";
import {useFormData} from "../../hooks/useFormData";
import useAuth from "../../hooks/useAuth";
import CartProductGiftCard from "./CartProductGiftCard";
import {getCartTotal} from "../../utils/getCartTotal";
import RodoConsent from "../LandingPage/RodoConsent";

const Cart = () => {
    const {totalCartItems, items} = useSelector((state) => state.cart.cart);
    const [total, setTotal] = useState(0);
    // const [allInStock, setAllInStock] = useState(true)
    const {allInStock} = useSelector((state) => state.cart.cart);
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth()
    const [showConsent, setShowConsent] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem("rodoAccepted");
        if (!consentGiven) {
            const timer = setTimeout(() => {
                setShowConsent(true); // Show the consent widget after a delay
            }, 2000); // 3-second delay
            return () => clearTimeout(timer); // Clean up the timer
        }
    }, []);

    useEffect(() => {
        if (!totalCartItems && !isLoggedIn) navigate("/");
        // setTotal(getCartTotal(items))
    }, [totalCartItems, navigate, isLoggedIn]);

    const updateTotal = useCallback((priceChange) => {
        setTotal(prevTotal => prevTotal + priceChange);
    }, []);

    if (totalCartItems) {
        const productsList = items.map((cartItem, i) => (
            cartItem.name === "Karta podarunkowa"
                ? <CartProductGiftCard
                    key={`${cartItem.id}-${i}`}
                    cartItem={cartItem}
                    updateTotal={updateTotal}
                    styling={['col-8', 'col-10']}/>
                : <CartProduct
                    key={`${cartItem.id}-${cartItem.size}-${cartItem.color}`}
                    cartItem={cartItem}
                    updateTotal={updateTotal}
                    // setAllInStock={setAllInStock}
                    styling={['col-8', 'col-10']}
                />
        ));

        return (
            <>
                <InnerSection>
                    {showConsent
                        ? <RodoConsent setShowConsent={setShowConsent}/>
                        : null}
                    <div className="cart__content">
                        <Link className="close-button" to="/"><Close/></Link>
                        <h2 className="cart__content-title">Koszyk</h2>
                        <div className="cart__content-products">
                            {productsList}
                        </div>
                        <div className='cart__content__summary'>
                            <p className='cart__content__summary-total'>Suma: {getCartTotal(items)} PLN</p>
                            <p className='cart__content__summary-info'>Koszt wysyłki wliczany jest w następnym
                                kroku </p>
                            <button
                                className='cart__content__summary-checkout link-button'
                                onClick={() => navigate('/order')}
                                disabled={!allInStock}
                            >DO KASY
                            </button>
                        </div>
                    </div>
                </InnerSection>
                <NewsLetter/>
            </>
        );
    } else {
        return <InnerSection><h2 className="cart__content-empty">Koszyk jest pusty</h2></InnerSection>
    }
};

export default Cart;
