import React, {useEffect, useState} from 'react';
import InnerSection from "../../components/Reusable/InnerSection";
import {useGetUnsubscribeQuery} from "../Admin/Subscriptions/subscriptionsApiSlice";
import {useParams} from "react-router-dom";
import {getErrorMessage} from "../../utils/getErrorMessage";
import ErrorPopup from "../../components/Reusable/ErrorPopup";

const Unsubscribe = () => {
    const {token} = useParams();
    const [err, setErr] = useState(null)
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUnsubscribeQuery(token, {
        skip: !token
    })

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    if (isLoading) return <h1>Wczytuję...</h1>
    if (isError) return (<>{err && <ErrorPopup message={err} onClose={clearError}/>}</>)
    if (isSuccess) {
        console.log(data.message);
        return (
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className="col-18 unsubscribe">
                    <div className="unsubscribe__content">
                        <h1 className="unsubscribe__content-title">SUBSKRYBCJA ANULOWANA</h1>
                        <p className="unsubscribe__content-text">Adres e-mail: <strong>{data.email}</strong> został
                            usunięty z naszej bazy.</p>
                    </div>
                </div>
            </InnerSection>
        );
    }
};

export default Unsubscribe;