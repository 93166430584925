import {useState} from "react";

const CustomSelect = ({placeholder, selected, setSelected, list, attribute, attributeId, className, translation}) => {
    const [isVisible, setIsVisible] = useState(false)
    const handleClick = (e) => {

        const country = {
            ...e.target.dataset,
            id: Number(e.target.dataset.id)
        }

        if (attributeId === "shipmentCountry") {
            setSelected(prev => ({
                ...prev,
                addresses: {
                    ...prev.addresses,
                    country
                },
            }))
            setIsVisible(!isVisible)
            return
        }
        if (attributeId === "billingCountry") {
            const country = {
                ...e.target.dataset,
                id: Number(e.target.dataset.id)
            }
            setSelected(prev => ({
                ...prev,
                addresses: {
                    ...prev.addresses,
                    billingAddress: {
                        ...prev.addresses.billingAddress,
                        country
                    }
                },
            }))
            setIsVisible(!isVisible)
            return
        }

        if (attributeId === "orderBillingCountry") {
            setSelected(prev => ({
                ...prev,
                billingAddress: {
                    ...prev.billingAddress,
                    country
                }
            }))
            setIsVisible(!isVisible)
            return
        }

        setSelected(prev => ({
            ...prev,
            [attribute]: {
                ...e.target.dataset,
                id: Number(e.target.dataset.id)
            },
        }))
        setIsVisible(!isVisible)
    }

    const optionsList = list.map(option => <li
        key={option.id}
        className={"option"}
        data-id={option.id}
        data-name={`${option.name}`}
        onClick={handleClick}>{option.name === "" ? "Brak" : translation
        ? translation[option.name]
        : option.name}
    </li>)
    return (
        <div className={`custom__select ${className}`}>
            <div
                className={isVisible ? "selected_option selected_option-visible" : "selected_option"}
                tabIndex={0}
                onClick={() => setIsVisible(!isVisible)}
            >
                        <span
                            className={selected?.id !== 0 ? "select__content" : "select__content select__content-hidden"}>
                            {selected?.id ?
                                translation
                                    ? translation[selected?.name]
                                    : selected?.name
                                : placeholder}
                        </span>
                <span className={"select_decor"}></span>
            </div>
            {isVisible && <ul className={"options"}>
                {/*{selected.id ? <li*/}
                {/*    key={0}*/}
                {/*    className={"option"}*/}
                {/*    data-id={0}*/}
                {/*    data-name=""*/}
                {/*    onClick={handleClick}>Brak*/}
                {/*</li> : null}*/}
                {optionsList}</ul>}
        </div>
    )
}

export default CustomSelect