import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";
import {useGetMarkdownQuery} from "../../app/appApiSlice";
import React, {useEffect, useState} from "react";
import {getErrorMessage} from "../../utils/getErrorMessage";
import DOMPurify from "dompurify";
import {marked} from "marked";
import ErrorPopup from "../../components/Reusable/ErrorPopup";

const About = () => {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMarkdownQuery('about.md');

    const [err, setErr] = useState()

    useEffect(() => {
        if (isError) {
            setErr(getErrorMessage(error));
        }
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    let content
    if (isLoading) content = <h1>Loading</h1>
    if (isSuccess && data) content =
        <div className="md-body"
             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(data.content))}}/>
    return (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <InnerSection>
                <div className='about__content'>
                    <div className='about__content__column about__content__column-left about__left'>
                        <h2 className="about__content__column-title"> O nas</h2>
                        {content}
                    </div>
                    <div className='about__content__column about__content__column-right'>
                        <p className="about__content__column-motto">
                            "Każdy szczyt<br/>góry jest w <br/>zasięgu ręki, jeśli <br/> tylko będziesz <br/> się
                            wspinać” <br/><span>Barry Finlay</span>
                        </p>
                    </div>
                </div>
            </InnerSection>
            <NewsLetter/>
        </>
    );
};

export default About;