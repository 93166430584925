import {useGetConfigQuery} from "../app/appApiSlice";
import {convertToRegEx} from "../utils/convertToRegEx";

const useConfig = () => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetConfigQuery('configData')

    if (data && isSuccess) {
        const REG_EX = convertToRegEx(data.REG_EX)
        return ({...data, REG_EX})
    }
}


export default useConfig