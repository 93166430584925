import React, {useEffect} from 'react';
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {
    useDeleteShipmentMutation,
    useGetShipmentsQuery,
} from "../../Shipments/ShipmentApiSlice";
import CheckBox from "../../../components/Reusable/CheckBox";

const AdminShipmentRow = React.memo(({id, setError, number, setState, dispatchList, i}) => {
    const {
        CONSTANTS: {
            SHIPMENT_STATUS_TRANSLATION,
            SHIPMENT_SERVICE_TRANSLATION,
            SENDING_METHOD_TRANSLATION
        }
    } = useConfig()

    const {shipment} = useGetShipmentsQuery("shipmentList", {
        selectFromResult: ({data}) => ({
            shipment: data?.entities[id]
        }),
    })

    const [deleteShipment, {
        data: deleteResult,
        isLoading: isDeleteLoading,
        isError: isDeleteError,
        error: deleteError,
        isSuccess: isDeleteSuccess
    }] = useDeleteShipmentMutation()

    useEffect(() => {
        if (isDeleteError) setError(getErrorMessage(deleteError));
    }, [deleteError, isDeleteError]);

    const onSelectShipmentClicked = (e) => {
        const {id: shipmentId, issingle} = e.target.dataset;
        const isSelected = dispatchList.some(el => el.id === shipmentId)

        setState(prev => ({
            ...prev,
            showDispatchForm: issingle === "true"
                ? !prev.showDispatchForm
                : prev.showDispatchForm,
            dispatchList: isSelected
                ? prev.dispatchList.filter(el => el.id !== shipmentId)
                : [...prev.dispatchList, {id: shipmentId, orderNumber: shipment.orderNumber}]
        }))
    }

    let result = null
    if (shipment) {
        const {created_at, shipmentId} = shipment
        const shipmentDate = format(parseISO(created_at), 'dd.MM.yyyy')
        const shipmentTime = format(parseISO(created_at), 'HH:mm:ss')
        const checked = dispatchList.some(el => el.id === shipmentId.toString())
        const canCreateDispatchOrder =
            (!shipment.custom_attributes?.dispatch_order_id &&
                shipment.sending_method === "dispatch_order") ||
            (!shipment.custom_attributes?.dispatch_order_id &&
                shipment.sending_method === null);

        result = (
            <div className="admin__panel__section__list admin__shipments__list ">
                <div className={`admin__panel__section__list-item col-1 ${number % 2 ? "even" : "odd"}`}>
                    {i + 1}
                    {canCreateDispatchOrder ?
                        <CheckBox
                            checked={checked}
                            setChecked={onSelectShipmentClicked}
                            className='list-item-checkbox'
                            name={shipmentId.toString()}
                        />
                        : null}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {shipment?.orderNumber}
                </div>
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    {shipment?.id}
                </div>
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    <div className="shipment-time">
                        <p>{shipmentDate} r.</p>
                        <p>godz: {shipmentTime}</p>
                    </div>
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {SHIPMENT_SERVICE_TRANSLATION[shipment?.service]}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"} status`}>
                    {SHIPMENT_STATUS_TRANSLATION[shipment?.status]}
                </div>
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"} status`}>
                    {shipment?.sending_method
                        ? SENDING_METHOD_TRANSLATION[shipment?.sending_method]
                        : "Odbiór przez kuriera"}
                </div>
                <div
                    className={`admin__panel__section__list-item admin__shipments__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {shipment.status === "created"
                        ? <Link
                            className="list-item-link"
                            to={`/admin/shipments/shipment?id=${id}`}>DANE
                        </Link>
                        : null}
                    {canCreateDispatchOrder
                        ? <button
                            type="button"
                            className="list-item-link"
                            data-id={shipmentId}
                            data-issingle="true"
                            onClick={onSelectShipmentClicked}>
                            ZLECENE ODBIORU
                        </button>
                        : null}
                    <Link
                        className="list-item-link"
                        to={`/admin/shipments/json?id=${id}`}>JSON
                    </Link>
                </div>
            </div>
        );
        return result
    }
})

export default AdminShipmentRow;



