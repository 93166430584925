export const adminValidateSubscription = (subscription, CONFIG) => {
    const validateField = (field, regex) => regex.test(field);
    const {REG_EX} = CONFIG

    const isValid = {
        email: validateField(subscription.email, REG_EX.EMAIL),
    }

    const all = Object.values(isValid).every(el => el === true)
    return {...isValid, all}
}