import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import {marked} from "marked";
import DOMPurify from 'dompurify';
import {MDE_OPTIONS} from "../../../config/easyMDEconfig";
import {useGetMarkdownQuery, useSaveMarkdownMutation} from "../../../app/appApiSlice";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminEditMarkdown = ({fileName, title, className, navigateAfter}) => {
    const [markdown, setMarkdown] = useState('');
    const [err, setErr] = useState(null)
    const navigate = useNavigate()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMarkdownQuery(`${fileName}`);

    const [saveMarkdown, {
        data: saveMarkdownData,
        isLoading: isSaveMarkdownLoading,
        isSuccess: isSaveMarkdownSuccess,
        isError: isSaveMarkdownError,
        error: saveMarkdownError
    }] = useSaveMarkdownMutation();

    useEffect(() => {
        if (isSuccess && data) setMarkdown(data.content)
    }, [data, isSuccess]);

    useEffect(() => {
        if (isSaveMarkdownSuccess) navigate(`${navigateAfter}`)
    }, [isSaveMarkdownSuccess, saveMarkdownData]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isSaveMarkdownError) setErr(getErrorMessage(saveMarkdownError));
    }, [error, isError, saveMarkdownError, isSaveMarkdownError]);

    const clearError = () => {
        setErr(null);
    };

    const handleChange = (value) => {
        setMarkdown(value);
    }

    const onSaveMarkDownClicked = async () => {

        try {
            await saveMarkdown({
                fileName,
                markdown
            }).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>{title}</h1>
            <div className="admin__panel__section__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button"
                      to="/admin">Wróć</Link>

                <button
                    className=" admin__panel__section__buttons-button yellow-button"
                    onClick={onSaveMarkDownClicked}
                >Zapisz
                </button>
            </div>
            <div className="md">
                <SimpleMDE
                    className="md__editor col-18 col-lg-9"
                    value={markdown}
                    onChange={handleChange}
                    options={MDE_OPTIONS}
                />
                <div className={`${className} md__preview md-body col-18 col-lg-9`}>
                    <h3 className="md__preview-title">Podgląd</h3>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(markdown))}}/>
                </div>
            </div>
        </section>)
}


export default AdminEditMarkdown;