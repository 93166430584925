import {apiSlice} from "./api/apiSlice";

export const appApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConfig: builder.query({
            query: () => ({
                url: `/api/config`,
                method: 'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
        }),
        getMarkdown: builder.query({
            query: fileName => ({
                url: `/api/config/markdown?fileName=${fileName}`,
                method: 'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            providesTags: (result, error, fileName) => [{type: 'Markdown', id: fileName}]
        }),
        saveMarkdown: builder.mutation({
            query: data => ({
                url: '/api/admin/markdown',
                method: 'POST',
                body: {...data},
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            invalidatesTags: (result, error, {fileName}) => [{type: 'Markdown', id: fileName}]
        }),
    })
})

// Export hooks for the authentication mutations
export const {
    useGetConfigQuery,
    useGetMarkdownQuery,
    useSaveMarkdownMutation,
} = appApiSlice;
