import {createEntityAdapter} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";

const newsletterAdapter = createEntityAdapter({});

const initialState = newsletterAdapter.getInitialState();

export const newsletterApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        getNewsletterHistory: builder.query({
            query: fileName => ({
                url: `/api/admin/newsletter/history`,
                method: 'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedNewsletters = responseData.map(newsletter => {
                    newsletter.id = newsletter._id
                    return newsletter
                }).sort((prev, curr) => curr.number - prev.number);
                return newsletterAdapter.setAll(initialState, loadedNewsletters)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Newsletter', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Newsletter', id}))
                    ];
                } else return [{type: 'Client', id: 'LIST'}];
            }
        }),

        deleteNewsletter: builder.mutation({
            query: (id) => ({
                url: '/api/admin/newsletter/history',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Newsletter', id: arg.id}
            ],
        }),

        getNewsletterTemplate: builder.query({
            query: fileName => ({
                url: `/api/admin/newsletter`,
                method: 'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            providesTags: (result, error, id) => [{type: 'Template', id: id}]
        }),
        sendNewsletter: builder.mutation({
            query: data => ({
                url: '/api/admin/newsletter',
                method: 'POST',
                body: {...data},
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            invalidatesTags: [{type: 'Newsletter', id: 'LIST'}]
        }),
    })
})

// Export hooks for the authentication mutations
export const {
    useGetNewsletterHistoryQuery,
    useGetNewsletterTemplateQuery,
    useDeleteNewsletterMutation,
    useSendNewsletterMutation,
} = newsletterApiSlice;
