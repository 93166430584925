import React, {useEffect, useState} from 'react';
import {useGetPaymentsQuery, useUpdatePaymentMutation} from "../../Payments/paymentApiSlice";
import {format, parseISO} from "date-fns";
import CustomSelect from "../../../components/Reusable/CustomSelect";
import {Link} from "react-router-dom";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {useUpdateOrderMutation} from "../../Orders/orderApiSlice";

const AdminPaymentRow = ({id, setError, number}) => {
    const [display, setDisplay] = useState(false)
    const {CONSTANTS: {PAYMENT_STATUS_LIST, PAYMENT_STATUS_TRANSLATION}} = useConfig()
    const [selected, setSelected] = useState({
        status: {
            name: "",
            id: ""
        }
    })

    const {payment} = useGetPaymentsQuery("paymentList", {
        selectFromResult: ({data}) => ({
            payment: data?.entities[id]
        }),
    })

    const [updatePayment, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdatePaymentMutation()

    useEffect(() => {
        if (payment && selected.status.name && selected.status.name !== payment.status) {
            setDisplay(true)
        }
    }, [selected]);

    useEffect(() => {
        if (isUpdateSuccess) setDisplay(false)
    }, [isUpdateSuccess]);

    useEffect(() => {
        if (payment || isUpdateError) {
            const status = PAYMENT_STATUS_LIST.find(el => el.name === payment.status) || ""
            setSelected({status})
        }
    }, [payment, isUpdateError]);

    useEffect(() => {
        if (isUpdateError) setError(getErrorMessage(updateError));
    }, [updateError, isUpdateError]);


    const onUpdatePaymentClicked = async () => {
        try {
            await updatePayment({
                id,
                status: selected.status.name
            }).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    let result = null
    if (payment) {
        const {createdAt} = payment
        const paymentDate = format(parseISO(createdAt), 'dd.MM.yyyy')
        const paymentTime = format(parseISO(createdAt), 'HH:mm:ss')
        const amount = payment.amount / 100


        result = (
            <div className="admin__panel__section__list admin__payments__list ">

                <div className={`admin__panel__section__list-item col-1 ${number % 2 ? "even" : "odd"}`}>
                    {payment?.orderNumber}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {amount}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    <div className="payment-time">
                        <span>{paymentDate} r.</span>
                        <span>godz: {paymentTime}</span>
                    </div>
                </div>
                <div className={`admin__panel__section__list-item col-5 ${number % 2 ? "even" : "odd"}`}>
                    {payment.email}
                </div>
                <div
                    className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"} status`}>
                    <CustomSelect
                        placeholder="status"
                        className={`status-select ${number % 2 ? "even" : "odd"}`}
                        selected={selected.status}
                        setSelected={setSelected}
                        attribute="status"
                        list={PAYMENT_STATUS_LIST}
                        translation={PAYMENT_STATUS_TRANSLATION}
                    />
                    {display
                        ? <div className="overlay">
                            <div className={display ? "popup__confirmation" : "popup__confirmation-hide"}>
                                <p className="popup__confirmation-text">
                                    Czy chcesz zmienić status tej płatności na: <span
                                    className="new-status"> {PAYMENT_STATUS_TRANSLATION[selected.status.name]}</span>?
                                </p>
                                <div className="popup__confirmation-buttons">
                                    <button
                                        className="popup__confirmation-button yellow-button"
                                        onClick={() => setDisplay(false)}
                                    >Anuluj
                                    </button>
                                    <button
                                        className="popup__confirmation-button yellow-button"
                                        onClick={onUpdatePaymentClicked}
                                    > Zmień
                                    </button>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                <div
                    className={`admin__panel__section__list-item admin__payments__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    <Link
                        className="list-item-link"
                        to={`/admin/payments/payment?id=${id}`}>DANE</Link>
                    <Link
                        className="list-item-link"
                        to={`/admin/payments/json?id=${id}`}>JSON</Link>
                </div>
            </div>
        );
        return result
    }
}

export default AdminPaymentRow;