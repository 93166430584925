import React, {useEffect, useState} from 'react';
import InnerSection from "../../../components/Reusable/InnerSection";
import {Link} from "react-router-dom";
import CheckBox from "../../../components/Reusable/CheckBox";
import {ReactComponent as Close} from "../../../images/close.svg";
import {validateClient} from "../../../utils/validateClient";
import {useAddNewClientMutation} from "../clientApiSlice";
import useSid from "../../../hooks/useSid";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import PasswordInput from "../../../components/Reusable/PasswordInput";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import SubmitButton from "../../../components/Reusable/SubmitButton";

const CreateAccount = () => {
    const sid = useSid();
    const [formError, setFormError] = useState("");
    const [err, setErr] = useState(null);
    const [check, setCheck] = useState(false);
    const {REG_EX, CONSTANTS: {PASSWORD_REQUIREMENTS}} = useConfig()


    // Initial state for form fields
    const [data, setData] = useState({
        name: "",
        surname: "",
        email: "",
        password: "",
        password2: "",
        subscribe: false,
        accept: false,
    });

    // Initial data for form fields' validity
    const [isValid, setIsValid] = useState({
        name: false,
        surname: false,
        email: false,
        password: false,
        password2: false,
        subscribe: false,
        accept: false,
        all: false,
    });

    const [
        addClient,
        {
            isSuccess,
            isLoading,
            isError,
            error
        }] = useAddNewClientMutation();

    useEffect(() => {
        setFormError(null);
        setIsValid(validateClient(data, REG_EX));
    }, [data]);

    useEffect(() => {
        if (isError) {
            const message = getErrorMessage(error)
            console.error(message)
            setErr(message)
        }
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const onSubscribeClicked = () => setData(prev => ({...prev, subscribe: !prev.subscribe}));

    const onAcceptClicked = () => setData(prev => ({...prev, accept: !prev.accept}));

    const setForm = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setData(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCheck(true);

        if (!isValid.name || !isValid.surname || !isValid.email) {
            setFormError("Wypełnij wymagane pola.");
            return;
        }

        if (!isValid.password) {
            setFormError(PASSWORD_REQUIREMENTS);
            return;
        }

        if (data.password !== data.password2) {
            setFormError("Hasła są niezgodne.");
            return;
        }

        if (!data.accept) {
            setFormError("Zaakceptuj warunki regulaminu i politykę prywatności.");
            return;
        }

        if (!isValid.all) return;

        try {
            await addClient({
                ...data,
                sid,
                addresses: {}
            }).unwrap();
        } catch (err) {

        }
    };

    let result;

    // Default form for account creation
    result = (
        <>
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className='register__content'>
                    <Link className="close-button" to="/"><Close/></Link>
                    <form
                        onSubmit={handleSubmit}
                        className='register__form'>
                        <h2 className="register__form-title"> Załóż konto</h2>
                        <input
                            className={!check ? 'custom-input' : isValid.email ? 'custom-input' : 'custom-input not-valid'}
                            type='email'
                            name='email'
                            placeholder='E-mail*'
                            value={data.email}
                            onChange={setForm}
                            autoComplete="on"
                        />

                        <input
                            className={!check ? 'custom-input' : isValid.name ? 'custom-input' : 'custom-input not-valid'}
                            type='text'
                            name='name'
                            placeholder='Imię*'
                            value={data.name}
                            onChange={setForm}
                            autoComplete="on"
                        />
                        <input
                            className={!check ? 'custom-input' : isValid.surname ? 'custom-input' : 'custom-input not-valid'}
                            type='text'
                            name='surname'
                            placeholder='Nazwisko*'
                            value={data.surname}
                            onChange={setForm}
                            autoComplete="on"

                        />

                        <PasswordInput
                            handlePwdInput={setForm}
                            check={check} isValid={isValid.password}

                        />
                        <PasswordInput
                            handlePwdInput={setForm}
                            check={check} isValid={isValid.password}
                            name="password2"
                            placeholder="Powtórz hasło*"
                        />

                        <div className="checkbox__container">
                            <CheckBox
                                name={"subscribe"}
                                label={"Dołącz do newslettera"}
                                checked={data.subscribe}
                                setChecked={onSubscribeClicked}
                            />
                            <CheckBox
                                name={"accept"}
                                label={"* Akceptuję warunki regulaminu i politykę prywatności"}
                                checked={data.accept}
                                setChecked={onAcceptClicked}
                            />
                        </div>
                        <SubmitButton
                            className="link-button"
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                            errorMessage={formError}
                            text="STWORZ KONTO"
                            isLoadingText="ZAKŁADAM KONTO..."
                        />
                    </form>
                </div>
            </InnerSection>
        </>
    );

    // Success message after account creation
    if (isSuccess) {
        result = (
            <InnerSection>
                <h1>Konto utworzone</h1>
                <p>Na twoją skrzynkę pocztową wysłaliśmy wiadomość e-mail z linkiem aktywacyjnym.</p>
                <p>Postępuj zgodnie z instrukcjami zawartmi w wiadomości.</p>
            </InnerSection>
        )
    }
    return result;
};

export default CreateAccount;
