import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import OrderForm from './OrderForm/OrderForm';
import OrderCart from './OrderCart/OrderCart';
import useLocalStorage from '../../hooks/useLocalStorage';
import {useFormData} from '../../hooks/useFormData';
import {useAddNewOrderMutation} from './orderApiSlice';
import validateOrderData from '../../utils/validateOrderData';
import useSid from "../../hooks/useSid";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import useAuth from "../../hooks/useAuth";
import {useSelector} from "react-redux";
import useConfig from "../../hooks/useConfig";
import {getErrorMessage} from "../../utils/getErrorMessage";

const Order = () => {
    const CONFIG = useConfig()
    const {
        isLoading: isFormDataLoading,
        isSuccess: isFormDataSuccess,
        formData
    } = useFormData();
    const sid = useSid();
    const {isLoggedIn} = useAuth()
    const [storageFormData, setStorageFormData] = useLocalStorage('orderFormData', null);
    const {totalCartItems} = useSelector((state) => state.cart.cart);
    const [order, setOrder] = useState(null);
    const [formError, setFormError] = useState();
    const [err, setErr] = useState();
    const [isValid, setIsValid] = useState(null);
    const [check, setCheck] = useState(false);
    const navigate = useNavigate();

    // RTK Query for adding a new order
    const [addNewOrder, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewOrderMutation();

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error))
    }, [error, isError]);

    useEffect(() => {
        if (!isFormDataLoading && formData) {
            setIsValid(validateOrderData(formData, CONFIG))
            setOrder(formData)
        }
    }, [isFormDataLoading]);

    // Redirect to home if cart is empty and order is not successful and client is not loading
    useEffect(() => {
        if (totalCartItems === 0 && !isSuccess && !isLoading && !isFormDataLoading) {
            navigate('/');
        }
    }, [totalCartItems, isSuccess, isLoading, navigate]);

    // Validate order data whenever the order state changes
    useEffect(() => {
        if (order) {
            setFormError(null);
            setIsValid(validateOrderData(order, CONFIG));
        }
    }, [order]);

    // Navigate to order summary page when order is successfully created
    useEffect(() => {
        if (isSuccess && data?.order) {
            navigate(`/order/${data.order._id}`);
        }
    }, [isSuccess, data, navigate]);

    const clearError = () => {
        setErr(null);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setCheck(true)

        // Check if form is valid
        if (!isValid.all) {
            setFormError('Dane zamówienia nie są kompletne.');
            console.log('Order data incomplete');
            return;
        }

        // Handle "remember me" functionality for unregistered Client
        if (order.remember && !isLoggedIn) {
            const formDataToSave = {
                ...order,
                products: [],
                payment: {
                    ...order.payment,
                    discount: {
                        code: "",
                        amount: 0,
                        type: ""
                    },
                    discountAmount: 0,
                    products: 0,
                    giftCards: 0,
                    shipment: 0,
                    total: 0,
                    totalBeforeDiscount: 0
                },
            };
            setStorageFormData(formDataToSave);
        } else if (storageFormData) {
            setStorageFormData(null);
        }

        // Add a new order
        try {
            await addNewOrder({...order, sid}).unwrap();
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    // Conditionally render the order form or the order summary
    let result = (
        <section className='inner-section order'>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {order && <div className='row order__content'>
                <OrderForm
                    order={order}
                    setOrder={setOrder}
                    isValid={isValid}
                    check={check}
                    isLoading={isLoading}
                    formError={formError}
                    handleSubmit={handleSubmit}
                />
                <OrderCart
                    order={order}
                    setOrder={setOrder}
                    setErr={setErr}
                />
            </div>}
        </section>
    );
    return totalCartItems !== 0 || isSuccess || isLoading ? result : null;
};

export default Order;
