import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import useConfig from "../../../hooks/useConfig";
import useGetLocations from "../../../hooks/useGetLocations";
import {adminValidateDiscount} from "../../../utils/adminValidateDiscount";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import {useGetSubscriptionsQuery, useUpdateSubscriptionMutation} from "./subscriptionsApiSlice";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {adminValidateSubscription} from "../../../utils/adminValidateSubscription";

const AdminEditSubscription = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const [subscription, setSubscription] = useState(null)
    const [check, setCheck] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const subscriptionId = useQuery('id');
    const CONFIG = useConfig()
    const locations = useGetLocations()
    locations.pop()

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetSubscriptionsQuery("subscriptionsList", {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[subscriptionId],
            ...other
        })
    })

    const [updateSubscription, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateSubscriptionMutation()

    useEffect(() => {
        if (isSuccess && data) {
            setSubscription(data)
            setIsValid(adminValidateSubscription(data, CONFIG));
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (subscription) {
            setFormError(null);
            setIsValid(adminValidateSubscription(subscription, CONFIG));
        }
    }, [subscription]);

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setErr(errorMessage);
        }
        if (isUpdateError) {
            const errorMessage = updateError?.data?.message || updateError?.error || 'An error occurred';
            setErr(errorMessage);
        }
    }, [error, isError, updateError, isUpdateError]);

    useEffect(() => {
        if (isUpdateSuccess) navigate("/admin/subscriptions")
    }, [updateResult, isUpdateSuccess]);

    const clearError = () => {
        setErr(null);
    };


    const onSaveDiscountClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!isValid.all) {
            setFormError('Dane są niepoprawne lub niekompletne.');
            console.log('Discount data incomplete');
            return
        }
        try {
            await updateSubscription(subscription).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err))
        }
    }

    const setInput = (e) => {
        const {value, dataset: {id}} = e.target;
        setSubscription(prev => ({...prev, [id]: id === "amount" ? Number(value) : value}))
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista subskrybcji"}
    </Link>)

    if (isLoading) return <h1>Loading...</h1>
    if (!subscription) return null;
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <div className="admin__panel__section__buttons-group">
                    <button
                        className=" admin__panel__section__buttons-button yellow-button"
                        onClick={onSaveDiscountClicked}
                    >{isUpdateLoading ? "Loading..." : "Zapisz"}
                    </button>
                </div>

            </div>
            <form className="admin__panel__section__from edit__client">
                <PanelSectionInput
                    isValid={isValid.email}
                    check={check}
                    value={subscription.email}
                    type="email"
                    setInput={setInput}
                    title="E-MAIL"
                    name="email"
                    placeholder="E-mail"
                />
            </form>
        </section>
    )
};

export default AdminEditSubscription;