import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";


const shipmentsAdapter = createEntityAdapter({});

const initialState = shipmentsAdapter.getInitialState();

export const shipmentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getShipments: builder.query({
            query: () => ({
                url: '/api/admin/shipments',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedShipments = responseData.map(shipment => {
                    shipment.shipmentId = shipment.id;
                    shipment.id = shipment._id;
                    return shipment;
                }).sort((prev, curr) => new Date(curr.created_at) - new Date(prev.created_at)); // Compare as dates


                return shipmentsAdapter.setAll(initialState, loadedShipments);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Shipment', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Shipment', id}))
                    ];
                } else return [{type: 'Shipment', id: 'LIST'}];
            }
        }),

        updateShipment: builder.mutation({
            query: (initialShipmentData) => ({
                url: '/api/admin/shipments',
                method: 'PATCH',
                body: {
                    ...initialShipmentData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Shipment', id: arg.id},
            ],
        }),

        deleteShipment: builder.mutation({
            query: (id) => ({
                url: '/api/admin/shipments',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Shipment', id: arg.id}
            ],
        })
    })
});

export const {
    useGetShipmentsQuery,
    useUpdateShipmentMutation,
    useAddNewShipmentMutation,
    useDeleteShipmentMutation,
} = shipmentApiSlice;

// returns the query object
export const selectShipmentsResult = shipmentApiSlice.endpoints.getShipments.select();
// returns the query object

// creates memoized selector
const selectShipmentsData = createSelector(
    selectShipmentsResult,
    shipmentsResult => shipmentsResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllShipments,
    selectById: selectShipmentById,
    selectIds: selectShipmentIds
} = shipmentsAdapter.getSelectors(state => selectShipmentsData(state) ?? initialState);
