import useConfig from "../../../hooks/useConfig";
import React, {useEffect} from "react";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import RemoveButton from "../../../components/Reusable/RemoveButton";
import {useDeleteDispatchOrderMutation, useGetDispatchOrdersQuery} from "../../DispatchOrders/dispatchOrderApiSice";
import {downloadDispatchOrderLabel} from "../../../utils/downloadDispatchOrderPrintout";
import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../Auth/authSlice";

const AdminDispatchOrderRow = ({id, setError, number}) => {
    const token = useSelector(selectCurrentToken)
    const {
        CONSTANTS: {DISPATCH_ORDER_STATUS_TRANSLATION, SERVER_ADDRESS}
    } = useConfig()

    const {dispatchOrder} = useGetDispatchOrdersQuery("dispatchOrdersList", {
        selectFromResult: ({data}) => ({
            dispatchOrder: data?.entities[id]
        }),
    })

    const [deleteDispatchOrder, {
        isError: isDeleteError,
        error: deleteError,
        isSuccess: isDeleteSuccess
    }] = useDeleteDispatchOrderMutation()

    useEffect(() => {
        if (isDeleteError) setError(getErrorMessage(deleteError));
    }, [deleteError, isDeleteError]);

    const onRemoveDispatchOrderClicked = async () => {
        try {
            const id = dispatchOrder.id
            const inPostId = dispatchOrder.dispatchOrderId
            await deleteDispatchOrder({id, inPostId}).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    const onGetPrintoutClicked = async () => {
        const url = `${SERVER_ADDRESS}/api/admin/shipments/dispatch_orders/printout`
        try {
            const result = await downloadDispatchOrderLabel(url, token, id, dispatchOrder.orderNumbers.join(", "))
            console.log(result);
        } catch (err) {
            const message = getErrorMessage(err)
            console.error(message)
            setError(message);
        }
    }

    let result = null
    if (dispatchOrder) {
        const {created_at} = dispatchOrder
        const dispatchOrderDate = format(parseISO(created_at), 'dd.MM.yyyy')
        const dispatchOrderTime = format(parseISO(created_at), 'HH:mm:ss')
        const canBeRemoved = dispatchOrder.status === 'new' || dispatchOrder.status === "sent"
        const orderNumbers = dispatchOrder.orderNumbers.join(", ")

        result = (
            <div className="admin__panel__section__list admin__dispatchOrders__list ">
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {orderNumbers}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {dispatchOrder?.external_id}
                </div>
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    <div className="dispatchOrder-time">
                        <p>{dispatchOrderDate} r.</p>
                        <p>godz: {dispatchOrderTime}</p>
                    </div>
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {dispatchOrder.address.street} {dispatchOrder.address.building_number}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"} status`}>
                    {DISPATCH_ORDER_STATUS_TRANSLATION[dispatchOrder.status === "new" ? "newDispatchOrder" : dispatchOrder.status]}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"} status`}>

                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"} status`}>
                </div>
                <div
                    className={`admin__panel__section__list-item admin__dispatchOrders__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    {canBeRemoved
                        ? <>
                            <button
                                type="button"
                                className="list-item-link button"
                                onClick={onGetPrintoutClicked}
                            >
                                POTWIERDZENIE NADANIA
                            </button>
                            {/*<Link*/}
                            {/*    className="list-item-link"*/}
                            {/*    to={`/admin/dispatchOrders/dispatchOrder?id=${id}`}>DANE*/}
                            {/*</Link>*/}
                            <RemoveButton
                                onRemove={onRemoveDispatchOrderClicked}
                                text={"Czy na pewno chcesz trwale usunąć to zlecenie przesyłki?"}
                                disabled={!canBeRemoved}
                            />
                        </>
                        : null}
                    <Link
                        className="list-item-link"
                        to={`/admin/dispatch_orders/json?id=${id}`}>JSON
                    </Link>
                </div>
            </div>
        );
        return result
    }
};

export default AdminDispatchOrderRow;