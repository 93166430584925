import {Link} from "react-router-dom";
import CheckBox from "../../../components/Reusable/CheckBox";
import useAuth from "../../../hooks/useAuth";

const SubscribeSection = ({isValid, check, order, setOrder}) => {
    const {isLoggedIn} = useAuth()

    const setSubscribe = (e) => {
        setOrder(prev => ({...prev, subscribe: e.target.checked}))
    }

    const onEmailChange = (e) => {
        setOrder(prev => ({...prev, email: e.target.value}))
    }

    return (
        <div className="form-section order__content__form__subscribe">
            <div className="order__content__form__subscribe__header">
                <h2 className="form-subtitle">Kontakt</h2>
                {!isLoggedIn && <Link className="order__content__form__subscribe__header-link" to='/login'>Zaloguj
                    się</Link>}
            </div>

            <input
                className={!check ? 'custom-input col-18' : isValid.isEmailValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                type="email"
                name='email'
                placeholder="e-mail"
                value={order?.email}
                onChange={onEmailChange}
                // autoComplete="on"
            />
            <CheckBox
                name="subscribe-checkbox"
                label="Wyrażam zgodę na otrzymywanie newslettera z promocjami i rabatami zgodnie z Regulaminem Sklepu."
                checked={order?.subscribe}
                setChecked={setSubscribe}
                autoComplete="on"
            />
        </div>
    );
};

export default SubscribeSection;